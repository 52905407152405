import React from 'react'

const TickerArrow = ({ color = '#fa0001', imgStyle = { width: '27.77px', height: '27.77px' } }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ ...imgStyle }} viewBox="0 0 27.771 27.771">
      <path data-name="Path 84" d="M0 0h27.771v27.771H0z" style={{ fill: 'none' }} />
      <path
        data-name="Path 85"
        d="M10.653 6 8.61 8.043l6.637 6.651-6.637 6.651 2.043 2.043 8.694-8.694z"
        transform="translate(.23 -.809)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default TickerArrow
