import React, { useContext } from 'react'
import { isEmpty } from 'lodash'
import { TemplateContext } from '../../context/template'
import MultiPartDetail from './multiPartDetail'
import NewMultipartDetail from './newMultipartDetail'

const MultiPartDetailTemplate = () => {
  const { contentDetailTemplateData, contentTemplateLoaded } = useContext(TemplateContext)

  const getPage = (key, data) => {
    if (!key) return
    let pageProps = {
      data,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
    }
    let componentObj = {
      newContentDetail: <NewMultipartDetail {...pageProps} />,
      contentDetailB: <NewMultipartDetail {...pageProps} />,
    }

    return componentObj[key]
  }

  return (
    <div className="w-full h-full">
      {contentTemplateLoaded ? (
        !isEmpty(contentDetailTemplateData) ? (
          getPage(contentDetailTemplateData.template_key, contentDetailTemplateData.data)
        ) : (
          <MultiPartDetail />
        )
      ) : null}
    </div>
  )
}

export default MultiPartDetailTemplate
