import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyleSportsFragmentedBanner'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { withTheme } from 'theming'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import './sportsFragmentedBanners.css'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
import { isEmpty } from 'lodash'
import PassimPlayer from '../rosemary/passimPlayer'
import BannerTrapezium from '../imgComponents/bannerTrapezium'
import BannerShareIcon from '../imgComponents/bannerShareIcon'
import BannerWatchListIcon from '../imgComponents/bannerWatchListIcon'

const SportsFragmentedBanner = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({})
  const { customNavigate, getTemplateImage, PrevArrow, NextArrow, LG_SIZE } = useTemplateHook()

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'flex-start',
          background: `transaprent`,
          width: '35px',
          height: '35px',
          position: 'absolute',
          top: '48%',
          right: '3.6%',
          content: `url(${NextArrow})`,
        }}
        onClick={onClick}
      >
        NextArrow
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: `transaprent`,
          width: '35px',
          height: '35px',
          position: 'absolute',
          top: '48%',
          left: '3.6%',
          content: `url(${PrevArrow})`,
          zIndex: '10',
        }}
        onClick={onClick}
      >
        PrevArrow
      </div>
    )
  }

  useEffect(() => {
    setSetting({
      arrows: window.innerWidth > LG_SIZE,
      nextArrow: <SampleNextArrow transform={128} />,
      prevArrow: <SamplePrevArrow transform={128} />,
      infinite: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      overflow: true,
      dots: true,
      adaptiveHeight: true,
      appendDots: (dots) => {
        return (
          <div
            style={{
              backgroundColor: 'transparent',
              borderRadius: '0px',
              padding: '0px',
              bottom: '5%',
              position: 'absolute',
              zIndex: 10,
            }}
          >
            <ul style={{ marginTop: '5px' }}>
              {dots.map((info, idx) => (
                <li key={idx} className={`${info.props.className}`}>
                  <div
                    style={{
                      backgroundColor: `${
                        info.props.className === 'slick-active' ? '#d9d9d9' : '#d9d9d950'
                      }`,
                    }}
                    className="ft-slick__dots--custom"
                    onClick={info.props.children.props.onClick}
                  >
                    <div className="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      },
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data?.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
      className={`w-full  relative ${isEdit && hoverState && 'component-hover-class'}`}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
          position={'top-20 right-4'}
          menuPosition={'top-24 right-4'}
        />
      )}
      <div className=" w-full h-full relative ">
        <SliderWrapper color={getStyleValue('component_background', 'primary_color', '#000')}>
          <Slider ref={sliderRef} {...setting}>
            {[...data?.slide_data].map((slide, idx) => (
              <div
                style={{
                  width: '100%',
                  aspectRatio: 1920 / 700,
                  marginTop: '0px',
                  cursor: 'pointer',
                }}
                key={idx}
              >
                <div className={`-z-10  img-gradient relative w-full h-full flex justify-end`}>
                  <div className="w-[61%] z-0">
                    {isEdit || !slide?.player?.type ? (
                      <CustomImage
                        imageUrl={getTemplateImage(slide?.image_s3key)}
                        imgClass=" w-full  z-0"
                        loaderClass="w-full  z-0"
                        imgStyle={{
                          aspectRatio: '1.67285',
                        }}
                        loaderStyle={{
                          background: '#5A5A5A50',
                          aspectRatio: '1.67285',
                        }}
                        imgAlt={'category'}
                        onClickRedirect={() => handleRedirect(slide.link)}
                      />
                    ) : (
                      <div
                        style={{
                          aspectRatio: '1.67285',
                        }}
                        className=" w-full h-full z-0"
                      >
                        <PassimPlayer
                          data={slide?.player}
                          poster={getTemplateImage(slide?.image_s3key)}
                          controlBar={false}
                          isBanner={true}
                        />
                      </div>
                    )}
                  </div>
                  <div className="w-[50%] absolute left-0 h-full flex justify-center items-center">
                    <BannerTrapezium
                      color={getStyleValue('component_background', 'shape_color', theme.c1)}
                      imgStyle={{ width: '99%' }}
                    />
                    <div className=" flex flex-col items-center absolute z-10">
                      <p
                        className={` w-full 2xl:text-[60px] xl:text-5xl md:text-xl sm:text-base text-sm font-bold line-clamp-1 mb-0
                  ${getStyleValue('heading', 'font_format', 'font-bold')}
                  `}
                        style={{
                          color: getStyleValue('heading', 'color', theme.c7),
                          fontFamily: getStyleValue('heading', 'font', ''),
                          textAlign: getStyleValue('heading', 'alignment', 'center'),
                          lineHeight: 'normal',
                        }}
                      >
                        {slide?.content?.heading}
                      </p>

                      <p
                        style={{
                          color: getStyleValue('sub_heading', 'color', theme.c7),
                          fontFamily: getStyleValue('sub_heading', 'font', ''),
                          textAlign: getStyleValue('sub_heading', 'alignment', 'center'),
                          lineHeight: 'normal',
                        }}
                        className={`w-full mt-1  md:mt-[13px] 2xl:text-[40px] xl:text-3xl font-normal md:text-lg text-sm ${getStyleValue(
                          'sub_heading',
                          'font_format',
                          '',
                        )} `}
                      >
                        {slide?.content?.sub_heading}
                      </p>
                      {slide?.cta_button?.enabled && (
                        <button
                          className="sm:px-[2.75ch] px-[11px] xl:py-[.6ch] lg:py-[.4ch] md:py-[.3ch] sm:py-[.3ch] py-[2px] font-semibold 2xl:text-xl xl:text-lg lg:text-base md:text-sm sm:text-xs text-[10px] mt-1 lg:mt-4 xl:mt-[30px]"
                          style={{
                            color: getStyleValue('button', 'color', theme.c6),
                            fontFamily: getStyleValue('button', 'font', ''),
                            backgroundColor: getStyleValue('button', 'button_background', theme.c7),
                            borderRadius: '4px',
                          }}
                          onClick={() => handleRedirect(slide.cta_button.link)}
                        >
                          {slide?.cta_button?.text}
                        </button>
                      )}

                      <div style={{ color: '#cbcbcb' }} className=" hidden md:flex ">
                        <p
                          style={{}}
                          className={`cursor-pointer  xl:mt-6 2xl:text-xl xl:text-lg lg:text-base md:text-sm sm:text-xs text-[10px] flex items-center`}
                          onClick={() => handleRedirect(slide.share_button.link)}
                        >
                          <BannerShareIcon />
                          <span className="ml-2">{slide?.share_button?.text} | </span>
                        </p>
                        <p
                          style={{}}
                          className={`cursor-pointer ml-2 xl:mt-6 2xl:text-xl xl:text-lg lg:text-base md:text-sm sm:text-xs text-[10px] flex items-center `}
                          onClick={() => handleRedirect(slide.watchlist_button.link)}
                        >
                          <BannerWatchListIcon />
                          <span className="ml-2"> {slide?.watchlist_button?.text}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </SliderWrapper>
      </div>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(SportsFragmentedBanner)
