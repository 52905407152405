import React, { useState } from 'react'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'

const SportsPromotionalBlock = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')

  const { customNavigate, getTemplateImage, VideoPlaceholder } = useTemplateHook()

  const handleHoverItems = (name) => {
    // if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative py-[16px] lg:py-8 `}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
      }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={() => handleModal()}
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div className="w-full flex justify-center">
        <div className="w-[90%]  flex flex-col justify-center items-center">
          <div className="flex w-full justify-start items-center">
            <p
              style={{
                color: getStyleValue('heading', 'color', '#fff'),
                whiteSpace: 'nowrap',
              }}
              className="text-lg xl:text-4xl 2xl:text-[40px] font-semibold mb-0"
            >
              {data?.name}
            </p>
            <div
              style={{
                border: `1.5px solid ${getStyleValue(
                  'component_background',
                  'line_color',
                  '#fc0001',
                )}`,
              }}
              className="w-full ml-4"
            ></div>
          </div>
          <CustomImage
            imageUrl={getTemplateImage(data?.image_s3key)}
            imgClass="w-full mt-2 md:mt-4 lg:mt-8 cursor-pointer"
            imgStyle={{ aspectRatio: '5.13368984' }}
            loaderClass="w-full mt-2 md:mt-4 lg:mt-8"
            loaderStyle={{ background: '#5A5A5A50', aspectRatio: '5.13368984' }}
            imgAlt={'recent story Img'}
            errorPlaceholder={VideoPlaceholder}
            onClickRedirect={() => handleRedirect(data.link)}
          />
        </div>
      </div>

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(SportsPromotionalBlock)
