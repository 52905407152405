import React, { useEffect, useState } from 'react'
import CustomImage from '../../components/customImage/customImage'
import useTemplateHook from '../../components/template/templateHook'
import { useTranslation } from 'react-i18next'
import useAxios from 'axios-hooks'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'

const EliteEdgeSignIn = (props) => {
  let { isEdit = false, theme, data, setModalState } = props

  console.log('props', data)
  const { t } = useTranslation()

  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')

  const [errorData, setErrorData] = useState({})

  const [hoverState, setHoverState] = useState('')
  // const {} = useContext(AppContext)
  const {
    customNavigate,
    getTemplateImage,
    setLoader,
    setOpenForgotPasswordModal,
    setUser,
    LOGIN_API,
    EditIcon,
  } = useTemplateHook()

  const [{ data: loginRes, loading: loginResLoading }, loginCall] = useAxios(
    {
      url: LOGIN_API,
      method: 'post',
    },
    { manual: true },
  )

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const validateInfo = (type) => {
    let isFormValid = true
    let temp = {}

    if (loginEmail.trim().length === 0) {
      isFormValid = false
      temp.loginEmail = `${t('email_empty')}!`
    }

    if (loginPassword.trim().length === 0) {
      isFormValid = false
      temp.loginPassword = `${t('password_empty')}!`
    }
    setErrorData({ ...temp })
    return isFormValid
  }

  const handleLogin = async () => {
    if (isEdit) return

    if (validateInfo('login')) {
      loginCall({
        data: {
          email_id: loginEmail,
          password: loginPassword,
        },
      })
    }
  }

  const handleChange = (event, func) => {
    const { value, name } = event.target

    if (errorData[`${name}`]) {
      delete errorData[`${name}`]
      setErrorData({ ...errorData })
    }
    func(value)
  }

  useEffect(() => {
    if (loginRes && loginRes.success) {
      setUser(loginRes?.data)
      customNavigate('DIRECT', '/')
    }
  }, [loginRes])

  useEffect(() => {
    setLoader(loginResLoading)
  }, [loginResLoading])

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const handleModal = (data) => {
    setModalState({ ...data })
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative`}
      onMouseEnter={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <div
          className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-4 right-4 z-10 flex justify-center items-center cursor-pointer"
          onClick={() => handleModal({ modalKey: 'eliteEdgeSignInModal', data: data })}
        >
          <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
        </div>
      )}
      <div
        className="w-full h-full flex justify-center relative"
        style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
      >
        <div className={`hidden xl:block w-full absolute`}>
          <CustomImage
            imageUrl={getTemplateImage(data.banner_data?.image_s3_key)}
            imgClass="w-full"
            loaderClass="w-full"
            loaderStyle={{
              background: '#5A5A5A50',
            }}
            imgStyle={{ aspectRatio: '1920/451' }}
            imgAlt={'banner_data'}
          />
        </div>
        <div
          style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
          className="w-[80%] xl:w-[700px] z-10 xl:mt-[270px] flex justify-center"
        >
          <div className="flex flex-col items-center xl:w-[500px] mt-10">
            <div
              className={` w-full flex justify-center ${
                isEdit && hoverState === 'IMAGE' && 'component-hover-class'
              } relative`}
              onMouseOver={() => handleHoverItems('IMAGE')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'IMAGE' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'logoModal',
                      data: data,
                      modalSpecificData: {
                        imgWidth: 105,
                        imgHeight: 100,
                        divWidth: '8.4rem',
                        divHeight: '8rem',
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <CustomImage
                imageUrl={getTemplateImage(data?.logo)}
                imgClass="w-[82px] xl:w-[105px]"
                loaderClass=""
                imgAlt={'image_s3key'}
              />
            </div>
            <div
              className={`${
                isEdit && hoverState === 'LOGIN_TEXT' && 'component-hover-class'
              } w-full text-center mt-16 flex flex-col items-center relative`}
              onMouseOver={() => handleHoverItems('LOGIN_TEXT')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'LOGIN_TEXT' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'loginTextModal',
                      data: data,
                      modalSpecificData: {
                        stylesConfigArr: ['login_heading', 'login_description'],
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <p
                style={{
                  color: getStyleValue('login_heading', 'color', theme.c5),
                  fontFamily: getStyleValue('login_heading', 'font', ''),
                  textAlign: getStyleValue('login_heading', 'alignment', ''),
                }}
                className={`${getStyleValue(
                  'login_heading',
                  'font_format',
                  '',
                )} mb-0 text-xl lg:text-[25px] font-bold`}
              >
                {data.login_text}
              </p>
              <p
                style={{
                  color: getStyleValue('login_description', 'color', theme.c3),
                  fontFamily: getStyleValue('login_description', 'font', ''),
                  textAlign: getStyleValue('login_description', 'alignment', ''),
                }}
                className={` mb-0 mt-4 text-xs xl:w-[300px] ${getStyleValue(
                  'login_description',
                  'font_format',
                  '',
                )}`}
              >
                {data.login_description}
              </p>
            </div>
            <div
              className={`w-full ${
                isEdit && hoverState === 'INPUT' && 'component-hover-class'
              } relative`}
              onMouseOver={() => handleHoverItems('INPUT')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'INPUT' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer"
                  onClick={() =>
                    handleModal({
                      modalKey: 'inputModal',
                      data: data,
                      modalSpecificData: {
                        stylesConfigArr: ['email_password_input', 'remember_text'],
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <input
                type="email"
                name="loginEmail"
                value={loginEmail}
                onChange={(e) => handleChange(e, setLoginEmail)}
                style={{
                  color: getStyleValue('email_password_input', 'text_color', theme.c4),
                  borderBottom: `1px solid ${getStyleValue(
                    'email_password_input',
                    'border_color',
                    theme.c4,
                  )}`,
                  background: getStyleValue(
                    'email_password_input',
                    'background_color',
                    'transparent',
                  ),
                }}
                className=" text-sm lg:text-base block w-full p-1 mt-8 focus:outline-none"
                placeholder={data.email_text}
              />
              {errorData?.loginEmail && (
                <div className="error-message">
                  <p>{errorData.loginEmail}</p>
                </div>
              )}
              <input
                type="password"
                name="loginPassword"
                value={loginPassword}
                onChange={(e) => handleChange(e, setLoginPassword)}
                style={{
                  color: getStyleValue('email_password_input', 'text_color', theme.c4),
                  borderBottom: `1px solid ${getStyleValue(
                    'email_password_input',
                    'border_color',
                    theme.c4,
                  )}`,
                  background: getStyleValue(
                    'email_password_input',
                    'background_color',
                    'transparent',
                  ),
                }}
                className=" text-sm lg:text-base block w-full p-1 mt-8 focus:outline-none"
                placeholder={data.password_text}
              />
              {errorData?.loginPassword && (
                <div className="error-message">
                  <p>{errorData.loginPassword}</p>
                </div>
              )}

              <div className="mt-4 flex justify-between">
                <div className="flex items-center">
                  <div className="flex items-center">
                    <input
                      id="remember"
                      type="checkbox"
                      defaultChecked={true}
                      className="h-4 w-4"
                    />
                  </div>
                  <div className=" ml-2">
                    <label
                      htmlFor="remember"
                      style={{
                        color: getStyleValue('remember_text', 'color', theme.c4),
                        fontFamily: getStyleValue('remember_text', 'font', ''),
                        textAlign: getStyleValue('remember_text', 'alignment', ''),
                      }}
                      className={` text-sm lg:text-base ${getStyleValue(
                        'remember_text',
                        'font_format',
                        '',
                      )}`}
                    >
                      {data.remember_text}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`mt-10 w-full ${
                isEdit && hoverState === 'BUTTON' && 'component-hover-class'
              } text-center relative`}
              onMouseOver={() => handleHoverItems('BUTTON')}
              onMouseLeave={() => handleHoverItems('TOP')}
            >
              {hoverState === 'BUTTON' && (
                <div
                  className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-2 right-2 flex justify-center items-center cursor-pointer z-10"
                  onClick={() =>
                    handleModal({
                      modalKey: 'buttonModal',
                      data: data,
                      modalSpecificData: {
                        stylesConfigArr: ['login_button', 'account_text', 'forgot_text'],
                      },
                    })
                  }
                >
                  <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
                </div>
              )}
              <button
                style={{
                  backgroundImage: `linear-gradient(to ${getStyleValue(
                    'login_button',
                    'gradient',
                    'left',
                  )}, ${getStyleValue('login_button', 'primary_color', theme.c5)}, ${getStyleValue(
                    'login_button',
                    'secondary_color',
                    theme.c5,
                  )})`,
                  color: getStyleValue('login_button', 'text_color', theme.c1),
                }}
                className="w-full  py-2 rounded  xl:text-lg font-medium focus:outline-none"
                onClick={handleLogin}
              >
                {data.login_button_text}
              </button>
              <p
                className={`mb-0 mt-8 text-sm lg:text-base ${getStyleValue(
                  'account_text',
                  'font_format',
                  '',
                )}`}
                style={{
                  color: getStyleValue('account_text', 'color', theme.c3),
                  fontFamily: getStyleValue('account_text', 'font', ''),
                  textAlign: getStyleValue('account_text', 'alignment', ''),
                }}
              >
                {data.account_text}{' '}
                <span
                  className="font-semibold cursor-pointer"
                  style={{ color: getStyleValue('account_text', 'register_text_color', theme.c5) }}
                  onClick={() => {
                    customNavigate('DIRECT', '/signup')
                  }}
                >
                  {data.register_text}
                </span>
              </p>
              <p
                className={`mb-0 mt-6 italic cursor-pointer text-sm lg:text-base ${getStyleValue(
                  'forgot_text',
                  'font_format',
                  '',
                )}`}
                style={{
                  color: getStyleValue('forgot_text', 'color', theme.c5),
                  fontFamily: getStyleValue('forgot_text', 'font', ''),
                  textAlign: getStyleValue('forgot_text', 'alignment', ''),
                }}
                onClick={() => setOpenForgotPasswordModal(true)}
              >
                {data.forgot_text}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(EliteEdgeSignIn)
