import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyleGloamingBannerSwimlane'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { withTheme } from 'theming'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import './decomposedBanners.css'
import AddSection from '../addSection'
import EditMenu from '../editMenu'
import PassimPlayer from '../rosemary/passimPlayer'
import DecomposedSwimlane from './decomposedSwimlane'
import { isEmpty } from 'lodash'

const GloamingBannerSwimlane = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id, config } = componentData
  const { customNavigate, getTemplateImage, PrevArrow, NextArrow, LG_SIZE } = useTemplateHook()

  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({})

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'flex',
          justifyContent: 'flex-start',
          background: `transaprent`,
          width: '35px',
          height: '35px',
          position: 'absolute',
          top: '48%',
          right: '3.6%',
          content: `url(${NextArrow})`,
        }}
        onClick={onClick}
      >
        NextArrow
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: `transaprent`,
          width: '35px',
          height: '35px',
          position: 'absolute',
          top: '48%',
          left: '3.6%',
          content: `url(${PrevArrow})`,
          zIndex: '10',
        }}
        onClick={onClick}
      >
        PrevArrow
      </div>
    )
  }

  useEffect(() => {
    setSetting({
      arrows: window.innerWidth > LG_SIZE,
      nextArrow: <SampleNextArrow transform={128} />,
      prevArrow: <SamplePrevArrow transform={128} />,
      infinite: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 3000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      overflow: true,
      dots: false,
      adaptiveHeight: true,
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  let swimlaneProps = {
    componentData: { data: data?.swimlane_data },
    isEdit: isEdit,
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
      className={`w-full relative ${isEdit && hoverState && 'component-hover-class'} z-10  `}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
          position={'top-20 right-4'}
          menuPosition={'top-24 right-4'}
        />
      )}

      <div className=" w-full h-full ">
        <SliderWrapper className="relative " isEdit={isEdit} hoverState={hoverState}>
          <Slider ref={sliderRef} className="" {...setting}>
            {data?.slide_data.map((slide, idx) => (
              <div key={idx} className="w-full h-full " onClick={() => handleRedirect(slide.link)}>
                <div className="flex justify-center items-center w-full h-full">
                  <div
                    className={`relative w-full xl:w-[84.5%] h-full flex z-10 justify-end`}
                    style={{
                      aspectRatio: '2.431784108',
                    }}
                  >
                    <div
                      style={{}}
                      className={`flex flex-col ${
                        config[slide?.content?.position]
                      } w-full h-full z-20 absolute p-3 xl:p-10 `}
                    >
                      <div className=" z-10 lg:w-[745px] drop-shadow">
                        <h1
                          style={{
                            color: getStyleValue('text', 'color', theme.c7),
                            fontFamily: getStyleValue('text', 'font', ''),
                            textAlign: getStyleValue('text', 'alignment', ''),
                          }}
                          className={`${getStyleValue(
                            'text',
                            'font_format',
                            'font-semibold',
                          )} md:text-2xl lg:text-3xl 2xl:text-[40px] mb-0`}
                        >
                          {slide.content.heading}
                        </h1>
                        <p
                          style={{
                            color: getStyleValue('text', 'color', theme.c7),
                            fontFamily: getStyleValue('text', 'font', ''),
                            textAlign: getStyleValue('text', 'alignment', ''),
                          }}
                          className={`hidden md:block mt-[16px] text-base leading-5 font-normal mb-2 
                      ${getStyleValue('text', 'font_format', '')}`}
                        >
                          {slide.content.sub_heading}
                        </p>
                        {slide.cta_button.enabled && (
                          <button
                            className="font-semibold py-1 px-3 md:py-[8px] md:px-9 border-none rounded md:rounded-lg tracking-normal  mt-[16px] lg:mt-[36px]"
                            style={{
                              color: getStyleValue('button', 'color', theme.c7),
                              fontFamily: getStyleValue('button', 'font', ''),
                              backgroundColor: getStyleValue(
                                'button',
                                'button_background',
                                theme.c3,
                              ),
                            }}
                          >
                            {slide.cta_button.text}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="w-full xl:w-[73%] relative aspect-video">
                      <div
                        style={{
                          backgroundImage: ` linear-gradient(to right,${getStyleValue(
                            'component_background',
                            'primary_color',
                            theme.c1,
                          )} 5%, ${`${getStyleValue(
                            'component_background',
                            'primary_color',
                            theme.c1,
                          )}90`} 51%, rgba(0, 0, 0, 0) 94%)`,
                        }}
                        className=" absolute w-1/3 h-full left-0 top-0 z-10"
                      />
                      {isEdit || !slide?.player?.type ? (
                        <CustomImage
                          imageUrl={getTemplateImage(slide?.image_s3key)}
                          imgClass={`z-0 w-full h-full `}
                          loaderClass="z-0 w-full h-full object-cover absolute"
                          loaderStyle={{
                            background: '#5A5A5A50',
                          }}
                          imgAlt={'class img"'}
                        />
                      ) : (
                        <div className=" z-0 w-full h-full">
                          <PassimPlayer
                            data={slide?.player}
                            poster={getTemplateImage(slide?.image_s3key)}
                            controlBar={false}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </SliderWrapper>
        <div className={`w-full  lg:pb-[50px] `} style={{ zIndex: isEdit ? 0 : 10 }}>
          <DecomposedSwimlane {...swimlaneProps} />
        </div>
      </div>

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(GloamingBannerSwimlane)
