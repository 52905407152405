import React from 'react'

const HeaderTrapezium = ({ color = '#fa0001', imgStyle = { width: '305px', height: '130px' } }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ ...imgStyle }} viewBox="0 0 305.541 130.002">
      <path
        data-name="Subtraction 3"
        d="M-3731.458 24207.6H-4037v-130h254.671l50.87 130z"
        transform="translate(4036.998 -24077.594)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default HeaderTrapezium
