import React, { useEffect, useState } from 'react'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import useAxios from 'axios-hooks'
import AddSection from '../addSection'
import PassimPlayer from '../rosemary/passimPlayer'
import CustomImage from '../../customImage/customImage'
import ProfileMediaArrow from '../imgComponents/profileMediaArrow'
import ReactHtmlParser from 'react-html-parser'

const ProfileMediaPlayer = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const [activeTab, setActiveTab] = useState(1)
  const {
    customNavigate,
    CONTENT_FILTER_XAVIER,
    getTemplateImage,
    VideoPlaceholder,
    SquareDummyImg,
    ThumbsUpIcon,
    SquareDraftIcon,
    SquareShareIcon,
    playerPageAllowed,
  } = useTemplateHook()
  const [contentData, setContentData] = useState([])
  const language = localStorage.getItem('language') || 'en'

  const [{ data: contentList }, contentListCall] = useAxios(
    {
      url: CONTENT_FILTER_XAVIER,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (!isEmpty(data?.tab_section1?.content_data) || !isEmpty(data?.tab_section1?.auto_payload)) {
      let filterPayload =
        data?.tab_section1?.type === 'STATIC'
          ? { content_ids: data?.tab_section1?.content_data }
          : {
              system_defined: data?.tab_section1?.auto_payload.filter(
                (info) => info.type === 'system_defined',
              ),
              custom_defined: data?.tab_section1?.auto_payload.filter(
                (info) => info.type === 'custom_defined',
              ),
              video_types: ['SPV', 'MPVP', 'MPVC', 'LIVE', 'VOD_LIVE', 'PLAYLIST'],
            }

      contentListCall({
        data: {
          filters: filterPayload,
          page: 1,
          order_by:
            data?.tab_section1?.type === 'STATIC'
              ? ''
              : data?.tab_section1?.order_by === 'title'
              ? `title.${language}`
              : data?.tab_section1?.order_by,
          reverse: data?.tab_section1?.type === 'STATIC' ? '' : data?.tab_section1?.reverse,
          page_size: 25,
        },
      })
    } else {
      setContentData([])
    }
  }, [data])

  useEffect(() => {
    if (contentList && contentList.success) {
      if (data.type === 'STATIC') {
        contentList?.data.sort((a, b) => {
          let sortingArr = data.content_data
          return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
        })
      }
      setContentData(contentList.data)
    }
  }, [contentList])

  const handleHoverItems = (name) => {
    // if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getLink = (info) => {
    if (info.video_type === 'PLAYLIST' || info.audio_type === 'PLAYLIST') {
      return `/playlist/${info.content_id}`
    }
    if (info.video_type === 'MPVP' || info.audio_type === 'MPAP') {
      return `/parent-content/${info.content_id}`
    } else {
      if ((playerPageAllowed && info.video_type === 'MPVC') || info.video_type === 'MPAC') {
        return `/series-watch/${info?.content_id}`
      }
      return `/content/${info.content_id}`
    }
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative py-[16px] lg:py-8 `}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
      }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={() => handleModal()}
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <div className="w-full flex justify-center items-center">
        <div className="w-[90%]  flex flex-col xl:flex-row xl:justify-between">
          <div className="w-full xl:w-[64.5%]">
            <p
              style={{ color: '#fff' }}
              className="text-lg xl:text-4xl 2xl:text-[40px] font-semibold mb-0"
            >
              {data?.heading}
            </p>
            <div className="flex mt-3 lg:mt-10">
              {[...data?.category_options].map((item, index) => (
                <p
                  style={{
                    marginBlockEnd: '0',
                    background: '#d6ff05',
                    padding: '2px 11px 2px 11px',
                    marginLeft: index ? '0.625vw' : '',
                  }}
                  className="text-[10px] font-semibold md:text-base line-clamp-1 cursor-pointer mb-0"
                  onClick={() => handleRedirect(item.link)}
                  key={index}
                >
                  {item.text}
                </p>
              ))}
            </div>

            <div style={{ aspectRatio: '1.7777' }} className="w-full mt-4 lg:mt-12 relative ">
              {isEdit || isEmpty(data?.player?.data) ? (
                <div className="w-full h-full relative flex justify-center items-center">
                  <CustomImage
                    imageUrl={getTemplateImage(data?.image_s3key)}
                    imgClass="w-full h-full"
                    loaderClass="w-full h-full"
                    loaderStyle={{ background: '#5A5A5A50' }}
                    imgAlt={'recent story Img'}
                    errorPlaceholder={VideoPlaceholder}
                  />
                </div>
              ) : (
                <div className="w-full h-full">
                  <PassimPlayer
                    data={data?.player}
                    poster={getTemplateImage(data?.image_s3key)}
                    onClickRedirect={() => {}}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="w-full xl:w-[34%] mt-4 xl:mt-0 flex flex-col justify-end ">
            <button
              className="font-semibold py-1 px-3 md:py-[8px] md:px-6 border-none tracking-normal w-fit text-sm xl:text-[22px] 2xl:text-[25px]"
              style={{
                color: getStyleValue('button', 'color', '#fff'),
                fontFamily: getStyleValue('button', 'font', ''),
                backgroundColor: getStyleValue('button', 'button_background', '#4466fd'),
              }}
              onClick={() => handleRedirect(data?.button?.link)}
            >
              {data?.button?.text}
            </button>
            <div className="w-full aspect-[1.118429386] xl:aspect-[1.129740519] mt-4 lg:mt-12">
              <div className="w-full flex items-center font-semibold">
                <div
                  className="flex justify-center items-center w-1/2 relative cursor-pointer"
                  onClick={() => setActiveTab(1)}
                >
                  <ProfileMediaArrow
                    color={activeTab === 1 ? '#fc0001' : '#fefefe'}
                    imgStyle={{ width: '100%' }}
                  />
                  <p
                    style={{ color: activeTab === 1 ? '#ffffff' : '#000000' }}
                    className="absolute text-sm xl:text-[22px] 2xl:text-[25px] mb-0"
                  >
                    {data.tab_section1.name}
                  </p>
                </div>
                <div
                  className="flex justify-center items-center w-1/2 relative cursor-pointer"
                  onClick={() => setActiveTab(2)}
                >
                  <ProfileMediaArrow
                    color={activeTab === 2 ? '#fc0001' : '#fefefe'}
                    imgStyle={{ width: '100%' }}
                  />
                  <p
                    style={{ color: activeTab === 2 ? '#ffffff' : '#000000' }}
                    className="absolute text-sm xl:text-[22px] 2xl:text-[25px] mb-0"
                  >
                    {data.tab_section2.name}
                  </p>
                </div>
              </div>
              <div
                style={{ marginTop: '2%' }}
                className="w-full h-[69.23vw] md:h-[40vw] xl:h-[22.552vw] hidden-scrollbar-class overflow-hidden"
              >
                {activeTab === 1 ? (
                  <>
                    {!isEmpty(contentData)
                      ? contentData.map((item, index) => {
                          return (
                            <div
                              className={`flex justify-between w-full ${index && 'mt-4 xl:mt-6'}`}
                              key={index}
                            >
                              <CustomImage
                                imageUrl={getTemplateImage(item?.square)}
                                imgClass="aspect-square w-[15.9%] cursor-pointer"
                                loaderClass="aspect-square w-[15.9%]"
                                loaderStyle={{ background: '#5A5A5A50' }}
                                imgAlt={'square Img'}
                                errorPlaceholder={SquareDummyImg}
                                onClickRedirect={() =>
                                  customNavigate('DIRECT', getLink(item), isEdit)
                                }
                              />
                              <div style={{ width: '80%' }}>
                                <p
                                  style={{
                                    background: '#d6ff05',
                                    padding: '2px 11px 2px 11px',
                                  }}
                                  className="cursor-pointer text-xs font-semibold md:text-sm line-clamp-1 w-fit mb-0"
                                  onClick={() => customNavigate('DIRECT', getLink(item), isEdit)}
                                >
                                  {item.title}
                                </p>
                                <p className="mb-0 text-xs xl:text-sm text-white mt-2 xl:mt-4 line-clamp-2">
                                  {item?.description}
                                </p>
                              </div>
                            </div>
                          )
                        })
                      : null}
                  </>
                ) : (
                  <>
                    <div style={{ lineHeight: '1.875rem' }} className={`custom-page `}>
                      <div style={{ color: '#fff' }} className="">
                        {!isEmpty(data) && ReactHtmlParser(data?.tab_section2?.content)}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="flex w-full justify-between items-center mt-[24px] xl:mt-[66px]">
              <div className="flex items-center">
                <img src={ThumbsUpIcon} alt="abcd" className="w-[38px] xl:w-[50px] aspect-square" />
                <img
                  src={SquareShareIcon}
                  alt="abcd"
                  className="w-[38px] xl:w-[50px] aspect-square ml-4"
                />
              </div>
              <img
                src={SquareDraftIcon}
                alt="abcd"
                className="w-[38px] xl:w-[50px] aspect-square"
              />
            </div>
          </div>
        </div>
      </div>

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(ProfileMediaPlayer)
