import React, { useEffect, useState } from 'react'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import useAxios from 'axios-hooks'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import SectionalComponentArrowXL from '../imgComponents/sectionalComponentArrowXL'

const SectionalComponentWithAd = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const [activeTab, setActiveTab] = useState(0)

  const {
    customNavigate,
    CONTENT_FILTER_XAVIER,
    getTemplateImage,
    convertDuration,
    getReleaseDate,
    PortraitDummyImg,
    playerPageAllowed,
  } = useTemplateHook()
  const [allContentData, setAllContentData] = useState([])
  const language = localStorage.getItem('language') || 'en'

  const [{ data: contentList }, contentListCall] = useAxios(
    {
      url: CONTENT_FILTER_XAVIER,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(async () => {
    if (!isEmpty(data?.tab_section)) {
      for (let i = 0; i < data?.tab_section.length; i++) {
        const element = data?.tab_section[i]

        let filterPayload =
          element?.type === 'STATIC'
            ? { content_ids: element?.content_data }
            : {
                system_defined: element?.auto_payload.filter(
                  (info) => info.type === 'system_defined',
                ),
                custom_defined: element?.auto_payload.filter(
                  (info) => info.type === 'custom_defined',
                ),
                video_types: ['SPV', 'MPVP', 'MPVC', 'LIVE', 'VOD_LIVE', 'PLAYLIST'],
              }

        if (!isEmpty(element?.content_data) || !isEmpty(element?.auto_payload)) {
          await contentListCall({
            data: {
              filters: filterPayload,
              page: 1,
              order_by:
                element?.type === 'STATIC'
                  ? ''
                  : element?.order_by === 'title'
                  ? `title.${language}`
                  : element?.order_by,
              reverse: element?.type === 'STATIC' ? '' : element?.reverse,
              page_size: 25,
            },
          })
        }
      }
    } else {
      setAllContentData([])
    }
  }, [data])

  useEffect(() => {
    if (contentList && contentList.success) {
      let tempAllContent = allContentData
      if (data.type === 'STATIC') {
        contentList?.data.sort((a, b) => {
          let sortingArr = data.content_data
          return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
        })
      }
      tempAllContent.push(contentList.data)
      setAllContentData([...tempAllContent])
    }
  }, [contentList])

  console.log('allContentData', allContentData)

  const handleHoverItems = (name) => {
    // if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const handleCategoryClick = (id) => {
    customNavigate('DIRECT', `/metadata/${id}`)
  }

  const getLink = (info) => {
    if (info.video_type === 'PLAYLIST' || info.audio_type === 'PLAYLIST') {
      return `/playlist/${info.content_id}`
    }
    if (info.video_type === 'MPVP' || info.audio_type === 'MPAP') {
      return `/parent-content/${info.content_id}`
    } else {
      if ((playerPageAllowed && info.video_type === 'MPVC') || info.video_type === 'MPAC') {
        return `/series-watch/${info?.content_id}`
      }
      return `/content/${info.content_id}`
    }
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'} relative py-[16px] lg:py-8 `}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
      }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={() => handleModal()}
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}

      <div className="w-full flex justify-center items-center">
        <div className="w-[90%]  flex flex-col xl:flex-row xl:justify-between">
          <div
            style={{
              borderTop: `1.5px solid ${getStyleValue(
                'component_background',
                'line_color',
                '#fc0001',
              )}`,
            }}
            className="w-full xl:w-[68.654%] max-h-[147.179vw] md:max-h-[100vw] xl:max-h-[89.3489vw] hidden-scrollbar-class overflow-hidden"
          >
            <div
              className="mt-4 xl:mt-10 flex justify-start hidden-scrollbar-horizontal"
              style={{
                overflowX: 'auto',
                overflowY: 'visible',
              }}
            >
              {!isEmpty(data.tab_section) &&
                [...data.tab_section].map((info, index) => {
                  return (
                    <div
                      className="flex justify-center items-center w-max h-5 md:h-[35px] pb-1 xl:h-[40px] 2xl:h-[50px] relative cursor-pointer"
                      onClick={() => setActiveTab(index)}
                      key={index}
                    >
                      <SectionalComponentArrowXL
                        color={activeTab === index ? '#fc0001' : '#fefefe'}
                        imgStyle={{ height: '100%' }}
                      />
                      <p
                        style={{ color: activeTab === index ? '#ffffff' : '#000000' }}
                        className="absolute text-[10px] md:text-lg xl:text-[24px] mb-0 font-semibold"
                      >
                        {info.name}
                      </p>
                    </div>
                  )
                })}
            </div>
            <div className=" w-full h-full  hidden-scrollbar-class mt-3 xl:mt-6">
              {!isEmpty(allContentData[activeTab])
                ? allContentData[activeTab].map((item, index) => {
                    return (
                      <div
                        className={`flex justify-between w-full items-center ${
                          index && 'mt-4 xl:mt-6'
                        } `}
                        key={index}
                      >
                        <CustomImage
                          imageUrl={getTemplateImage(item?.portrait)}
                          imgClass="aspect-[280/380] w-[23.85%] cursor-pointer"
                          loaderClass="aspect-[280/380] w-[23.85%]"
                          loaderStyle={{ background: '#5A5A5A50' }}
                          imgAlt={'square Img'}
                          errorPlaceholder={PortraitDummyImg}
                          onClickRedirect={() => customNavigate('DIRECT', getLink(item), isEdit)}
                        />
                        <div style={{ width: '73%' }} className="h-full text-white">
                          <p
                            style={{ lineHeight: 'normal' }}
                            className="cursor-pointer text-xs font-semibold md:text-lg xl:text-[32px] 2xl:text-[38px] line-clamp-1 w-fit mb-0"
                            onClick={() => customNavigate('DIRECT', getLink(item), isEdit)}
                          >
                            {item.title}
                          </p>
                          <p className="mb-0 text-[10px] md:text-lg xl:text-xl  mt-[8px] line-clamp-1">
                            {item.video_type}
                          </p>
                          <p
                            style={{ color: '#b2b2b2' }}
                            className="mb-0 text-[10px] md:text-lg  mt-[4px]  xl:mt-6 2xl:mt-[33px] "
                          >
                            <span className="italic">
                              Run Time : {convertDuration(item?.duration_in_s)}{' '}
                            </span>{' '}
                            |{' '}
                            <span className="italic">
                              Date Published : {getReleaseDate(item.publish_time)}
                            </span>
                          </p>
                          <p
                            style={{ color: '#b2b2b2' }}
                            className="mb-0 text-[10px] md:text-lg  mt-[8px] 
                           xl:mt-6 2xl:mt-[33px] line-clamp-3 "
                          >
                            {item?.description}
                          </p>
                          <div className="flex mt-[4px] xl:mt-6 2xl:mt-10 text-black">
                            {item?.category
                              .filter((e, i) => i < 4)
                              .map((info, idx) => (
                                <p
                                  style={{
                                    background: '#d6ff05',
                                    marginLeft: idx ? '0.625vw' : '',
                                  }}
                                  className={` text-[8px] font-semibold md:text-base line-clamp-1 w-fit mb-0 px-1 xl:px-3 xl:py-1 cursor-pointer`}
                                  key={idx}
                                  onClick={() => handleCategoryClick(info.id)}
                                >
                                  {info.name}
                                </p>
                              ))}
                          </div>
                        </div>
                      </div>
                    )
                  })
                : null}
            </div>
          </div>
          <div className="w-full xl:w-[29.239%] mt-4 xl:mt-0">
            {data?.ad_section1.image_s3key ? (
              <div className="mt-4 xl:mt-10 w-full cursor-pointer ">
                <CustomImage
                  imageUrl={getTemplateImage(data?.ad_section1?.image_s3key)}
                  imgClass="aspect-[500/678] w-full"
                  loaderClass="aspect-[500/678] w-full"
                  loaderStyle={{ background: '#5A5A5A50' }}
                  imgAlt={'square Img'}
                  errorPlaceholder={PortraitDummyImg}
                  onClickRedirect={() => handleRedirect(data.ad_section1.link)}
                />
              </div>
            ) : null}
            {data?.ad_section2.image_s3key ? (
              <div className="mt-4 xl:mt-10 w-full  cursor-pointer">
                <CustomImage
                  imageUrl={getTemplateImage(data?.ad_section2?.image_s3key)}
                  imgClass="aspect-[500/678] w-full"
                  loaderClass="aspect-[500/678] w-full"
                  loaderStyle={{ background: '#5A5A5A50' }}
                  imgAlt={'square Img'}
                  errorPlaceholder={PortraitDummyImg}
                  onClickRedirect={() => handleRedirect(data.ad_section2.link)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(SectionalComponentWithAd)
