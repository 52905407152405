import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './_SlickSliderStyle'
import EditMenu from '../editMenu'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import PassimPlayer from '../rosemary/passimPlayer'

const RowingHeroSectionPlayer = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id, config } = componentData
  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({})
  const { customNavigate, getTemplateImage, LeftArrow, RightArrow } = useTemplateHook()

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  function SampleNextArrow(props) {
    const { className, style, onClick, transform } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'transparent',
          width: '54px',
          height: '54px',
          position: 'absolute',
          top: '50%',
          right: '0px',
          transform: `translateX(-${transform}px) translateY(-50%)`,
          content: `url(${RightArrow})`,
        }}
        onClick={onClick}
      >
        NextArrow
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, transform } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'transparent',
          width: '54px',
          height: '54px',
          position: 'absolute',
          top: '50%',
          left: '0px',
          transform: `translateX(${transform}px) translateY(-50%)`,
          content: `url(${LeftArrow})`,
          zIndex: '10',
        }}
        onClick={onClick}
      >
        PrevArrow
      </div>
    )
  }
  useEffect(() => {
    setSetting({
      dots: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 2500,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '210px',
      className: 'center',
      pauseOnHover: true,
      adaptiveHeight: true,
      nextArrow: <SampleNextArrow transform={130} />,
      prevArrow: <SamplePrevArrow transform={130} />,
      appendDots: (dots) => {
        return (
          <div
            style={{
              backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
              borderRadius: '0px',
              padding: '0px',
              bottom: '-10px',
            }}
          >
            <ul style={{ marginTop: '5px' }}>
              {dots.map((info, idx) => (
                <li key={idx} className={`${info.props.className}`}>
                  <div
                    style={{
                      backgroundColor: `${
                        info.props.className === 'slick-active'
                          ? getStyleValue('dots_color', 'active_color', theme.c3)
                          : getStyleValue('dots_color', 'inactive_color', '')
                      }`,
                    }}
                    className="ft-slick__dots--custom"
                    onClick={info.props.children.props.onClick}
                  >
                    <div className="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      },
      customPaging: (i) => (
        <div className="ft-slick__dots--custom">
          <div className="" />
        </div>
      ),
      responsive: [
        {
          breakpoint: 1536,
          settings: {
            centerPadding: '180px',
            nextArrow: <SampleNextArrow transform={96} />,
            prevArrow: <SamplePrevArrow transform={96} />,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            centerPadding: '150px',
            nextArrow: <SampleNextArrow transform={64} />,
            prevArrow: <SamplePrevArrow transform={64} />,
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: '100px',
            nextArrow: <SampleNextArrow transform={32} />,
            prevArrow: <SamplePrevArrow transform={32} />,
          },
        },
        {
          breakpoint: 640,
          settings: {
            centerPadding: '50px',
            arrows: false,
          },
        },
      ],
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data?.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
      className={` w-full 2xl:h-[82vh] lg:h-[72vh] md:h-[62vh] sm:h-[42vh] h-[180px] relative  ${
        isEdit && hoverState && 'component-hover-class'
      }`}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
        />
      )}
      {!isEmpty(setting) && (
        <SliderWrapper className="">
          <Slider ref={sliderRef} {...setting}>
            {data?.slide_data.map((slide, idx) => (
              <div
                key={idx}
                className={`2xl:h-[80vh] lg:h-[70vh] md:h-[60vh] sm:h-[40vh] h-[175px]`}
                style={{ width: '100%' }}
              >
                <div
                  className={`relative flex flex-col w-full h-full ${
                    config[slide.content.position]
                  } `}
                  onClick={() => handleRedirect(slide.link)}
                >
                  {isEdit || !slide?.player?.type ? (
                    <CustomImage
                      imageUrl={getTemplateImage(slide?.image_s3key)}
                      imgClass="absolute -z-10 w-full h-full"
                      loaderClass="w-full h-full absolute -z-10"
                      loaderStyle={{
                        background: '#5A5A5A50',
                      }}
                      imgAlt={'category'}
                    />
                  ) : (
                    <div className="absolute -z-10 w-full h-full">
                      <PassimPlayer
                        data={slide?.player}
                        poster={getTemplateImage(slide?.image_s3key)}
                        controlBar={false}
                        isBanner={true}
                        iconPositionClass={'decomposed-banner-player-icon'}
                        onClickRedirect={() => handleRedirect(slide.link)}
                      />
                    </div>
                  )}
                  <div className="z-10 mb-10 lg:mb-0 lg:px-14 lg:py-14">
                    <div className="z-10">
                      <h1
                        style={{
                          color: getStyleValue('heading', 'color', theme.c2),
                          fontFamily: getStyleValue('heading', 'font', ''),
                          textAlign: getStyleValue('heading', 'alignment', ''),
                        }}
                        className={` md:text-2xl text-base lg:text-4xl   ${getStyleValue(
                          'heading',
                          'font_format',
                          'font-bold',
                        )} tw-line-clamp-2`}
                      >
                        {slide.content.heading}
                      </h1>
                      <h3
                        style={{
                          color: getStyleValue('sub_heading', 'color', theme.c2),
                          fontFamily: getStyleValue('sub_heading', 'font', ''),
                          textAlign: getStyleValue('sub_heading', 'alignment', ''),
                        }}
                        className={` font-normal md:text-lg text-sm lg:text-2xl ${getStyleValue(
                          'sub_heading',
                          'font_format',
                          'italic',
                        )} tw-line-clamp-2`}
                      >
                        {slide.content.sub_heading}
                      </h3>
                    </div>
                    {slide?.cta_button?.enabled && (
                      <button
                        style={{
                          color: getStyleValue('button', 'color', theme.c5),
                          fontFamily: getStyleValue('button', 'font', ''),
                          backgroundColor: getStyleValue('button', 'button_background', theme.c2),
                        }}
                        className={`border-0 px-[0.5rem] py-[0.25rem] md:mt-1 md:py-1 md:px-3 md:text-lg text-xs font-normal rounded-full z-10`}
                      >
                        {slide.cta_button.text}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </SliderWrapper>
      )}

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(RowingHeroSectionPlayer)
