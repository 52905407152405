import React from 'react'

const SectionalComponentArrowXL = ({
  color = '#fff',
  imgStyle = { width: '344.123px', height: '49.99px' },
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ ...imgStyle }} viewBox="0 0 344.32 50.004">
      <path
        data-name="Subtraction 11"
        d="M2416.418 24882.3h-330.362l12.632-25.256-.031-.031.063-.068-12.824-24.648h330.334l12.583 24.648-12.39 25.354zm13.387 0h-.467l.467-.955v.955zm-344.318-24.84v-.781l.2.385zm344.316-24.09-.549-1.074h.551v1.074z"
        transform="translate(-2085.486 -24832.297)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default SectionalComponentArrowXL
