import React from 'react'

const BannerWatchListIcon = ({
  color = '#cbcbcb',
  imgStyle = { width: '21.563px', height: '21.552px' },
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={imgStyle} viewBox="0 0 21.563 21.552">
      <g data-name="Group 1225" transform="translate(-1718.07 -797.447)">
        <g
          data-name="Rectangle 1615"
          transform="translate(1718.07 800.463)"
          style={{ stroke: color, strokeWidth: '2px', fill: 'none' }}
        >
          <rect width="18.537" height="18.537" rx={4} style={{ stroke: 'none' }} />
          <rect x={1} y={1} width="16.537" height="16.537" rx={3} style={{ fill: 'none' }} />
        </g>
        <rect
          data-name="Rectangle 1616"
          width="11.122"
          height="11.122"
          rx={2}
          transform="translate(1721.777 804.17)"
          style={{ fill: color }}
        />
        <path data-name="Rectangle 1621" d="M1728.602 800.463h6v2h-6z" />
        <path data-name="Rectangle 1622" d="M1734.602 807.463v-6h2.005v6z" />
        <path
          data-name="add-svgrepo-com (4)"
          d="M16.031 13.019h-3.012v3.012h-2.007v-3.012H8v-2.007h3.012V8h2.008v3.012h3.012z"
          transform="translate(1723.602 789.447)"
          style={{ fill: color }}
        />
      </g>
    </svg>
  )
}

export default BannerWatchListIcon
