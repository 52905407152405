import React from 'react'
import CustomImage from '../customImage/customImage'

const ResultsCard = ({ data }) => {
  return (
    <div
      style={{
        aspectRatio: '1102/270',
        borderRadius: '16px',
        border: 'solid 1px #98baff',
        backgroundImage: 'linear-gradient(to right, #d4e4ff 6%, #f9f9f9 50%, #d4e4ff 94%)',
        paddingLeft: '8.89%',
        paddingRight: '3.63%',
      }}
      className={`mt-4 w-full py-2.5`}
    >
      <div className="flex justify-between items-center">
        <p className="xl:text-xl">{data.league}</p>
        <p style={{ color: '#4b4b4b' }} className="">
          {data.date} {data.venue}
        </p>
      </div>
      <div className="flex w-full items-center justify-between mt-6">
        <div style={{ width: '28%' }}>
          <div style={{}} className="flex justify-between">
            <div
              style={{ width: '76px', height: '76px' }}
              className="border flex justify-center items-center overflow-hidden p-1"
            >
              <CustomImage
                imageUrl={data.team1.logo}
                imgClass="w-full "
                loaderClass={'w-full h-full'}
                imgAlt={`data.team1.logo`}
              />
            </div>
            <div
              style={{ width: '174px', height: '76px' }}
              className="flex flex-col justify-center"
            >
              <p>{data.team1.name}</p>
              <p style={{ color: '#4b4b4b' }} className="text-xs">
                {data.team1.country}
              </p>
            </div>
          </div>
          <div style={{ width: '76px' }} className=" text-center py-2.5 font-bold">
            VS
          </div>
          <div style={{}} className="flex justify-between">
            <div
              style={{ width: '76px', height: '76px' }}
              className="border flex justify-center items-center overflow-hidden p-1"
            >
              <CustomImage
                imageUrl={data.team2.logo}
                imgClass="w-full "
                loaderClass={'w-full h-full'}
                imgAlt={`data.team1.logo`}
              />
            </div>
            <div
              style={{ width: '174px', height: '76px' }}
              className="flex flex-col justify-center"
            >
              <p>{data.team2.name}</p>
              <p style={{ color: '#4b4b4b' }} className="text-xs">
                {data.team2.country}
              </p>
            </div>
          </div>
        </div>
        <div style={{ width: '25%' }} className="">
          <div
            style={{ color: '#3074ff', borderBottom: '2px solid #aecafb' }}
            className="w-full flex justify-between"
          >
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span className="italic font-semibold">T</span>
          </div>
          <div style={{ color: '#7f7f7f' }} className="w-full flex justify-between mt-3">
            <span>{data.score.team1.q1}</span>
            <span>{data.score.team1.q2}</span>
            <span>{data.score.team1.q3}</span>
            <span>{data.score.team1.q4}</span>

            <span className="italic text-lg font-semibold">{data.score.team1.f}</span>
          </div>
          <div style={{ color: '#000' }} className="w-full flex justify-between mt-3">
            <span>{data.score.team2.q1}</span>
            <span>{data.score.team2.q2}</span>
            <span>{data.score.team2.q3}</span>
            <span>{data.score.team2.q4}</span>
            <span
              style={{}}
              className="relative italic text-lg font-semibold flex justify-center items-center"
            >
              {data.score.team2.f}
              <span style={{ right: '-25px' }} className=" mt-1 absolute rotate-180 ">
                &#10148;
              </span>
            </span>
          </div>
        </div>
        <div style={{ width: '28%' }}>
          <div style={{ color: '#3074ff', borderBottom: '2px solid #aecafb' }} className="w-full">
            <span className="italic font-semibold">TOP PERFORMERS</span>
          </div>
          <div className="mt-2 flex items-center">
            <div
              style={{ width: '44px', height: '44px' }}
              className="border flex justify-center items-center overflow-hidden"
            >
              <CustomImage
                imageUrl={data.topPerformers.player1.img}
                imgClass="w-full "
                loaderClass={'w-full h-full'}
                imgAlt={`data.team1.logo`}
              />
            </div>
            <div className="xl:ml-6">
              <p className="font-semibold">{data.topPerformers.player1.name}</p>
              <p className=" text-xs flex items-center">
                {data.topPerformers.player1.data.map((info, index) => (
                  <>
                    {info}
                    {index < data.topPerformers.player1.data.length - 1 ? (
                      <div
                        style={{ background: '#4b4b4b', width: '5px', height: '5px' }}
                        className="rounded-full mx-1"
                      />
                    ) : null}
                  </>
                ))}
              </p>
            </div>
          </div>
          <div className="mt-4 flex items-center">
            <div
              style={{ width: '44px', height: '44px' }}
              className="border flex justify-center items-center overflow-hidden"
            >
              <CustomImage
                imageUrl={data.topPerformers.player2.img}
                imgClass="w-full "
                loaderClass={'w-full h-full'}
                imgAlt={`data.team1.logo`}
              />
            </div>
            <div className="xl:ml-6">
              <p className="font-semibold">{data.topPerformers.player2.name}</p>
              <p className="flex items-center text-xs">
                {data.topPerformers.player2.data.map((info, index) => (
                  <>
                    {info}
                    {index < data.topPerformers.player2.data.length - 1 ? (
                      <div
                        style={{ background: '#4b4b4b', width: '5px', height: '5px' }}
                        className="rounded-full mx-1"
                      />
                    ) : null}
                  </>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResultsCard
