import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import SliderWrapper from './bohemiaSlickSlider'
import EditMenu from '../editMenu'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { isEmpty } from 'lodash'
import { withTheme } from 'theming'
import AddSection from '../addSection'
import CustomImage from '../../customImage/customImage'
import useTemplateHook from '../templateHook'
import PassimPlayer from '../rosemary/passimPlayer'

const BohemiaBanner = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onSwap,
  onDelete,
  onCopy,
  theme,
  onAdd,
}) => {
  const sliderRef = useRef(null)
  const { data, id, config } = componentData
  const [hoverState, setHoverState] = useState('')
  const [setting, setSetting] = useState({})
  const [centerMode, setCenterMode] = useState(true)
  const { customNavigate, getTemplateImage, PrevArrow, NextArrow, LG_SIZE } = useTemplateHook()
  const [currentSlideNumber, setCurrentSLideNumber] = useState(0)

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setCenterMode(true)
    } else {
      setCenterMode(false)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  function SampleNextArrow(props) {
    const { className, style, onClick, transform } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'transparent',
          width: '35px',
          height: '35px',
          position: 'absolute',
          top: '50%',
          right: '0px',
          transform: `translateX(-${transform}px) translateY(-50%)`,
          content: `url(${NextArrow})`,
        }}
        onClick={onClick}
      >
        NextArrow
      </div>
    )
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, transform } = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          background: 'transparent',
          width: '35px',
          height: '35px',
          position: 'absolute',
          top: '50%',
          left: '0px',
          transform: `translateX(${transform}px) translateY(-50%)`,
          content: `url(${PrevArrow})`,
          zIndex: '10',
        }}
        onClick={onClick}
      >
        PrevArrow
      </div>
    )
  }
  useEffect(() => {
    setSetting({
      dots: true,
      autoplay: data?.auto_slide,
      autoplaySpeed: 2500,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: centerMode,
      centerPadding: '204px',
      className: 'center',
      pauseOnHover: true,
      adaptiveHeight: true,
      nextArrow: <SampleNextArrow transform={130} />,
      prevArrow: <SamplePrevArrow transform={130} />,
      appendDots: (dots) => {
        return (
          <div
            style={{
              backgroundColor: 'transparent',
              borderRadius: '0px',
              padding: '0px',
              bottom: '1vw',
            }}
            className=""
          >
            <ul>
              {dots.map((info, idx) => (
                <li key={idx} className={`${info.props.className}`}>
                  <div
                    style={{
                      backgroundColor: `${
                        info.props.className === 'slick-active'
                          ? getStyleValue('dots_color', 'active_color', `#ffffff`)
                          : getStyleValue('dots_color', 'inactive_color', '#ffffff')
                      }`,
                      opacity: `${info.props.className === 'slick-active' ? '1' : '0.3'}`,
                    }}
                    className="ft-slick__dots--custom"
                    onClick={info.props.children.props.onClick}
                  >
                    <div className="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
      },
      customPaging: (i) => (
        <div className="ft-slick__dots--custom">
          <div className="" />
        </div>
      ),
      responsive: [
        {
          breakpoint: 1536,
          settings: {
            centerPadding: '180px',
            nextArrow: <SampleNextArrow transform={96} />,
            prevArrow: <SamplePrevArrow transform={96} />,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            centerPadding: '150px',
            nextArrow: <SampleNextArrow transform={64} />,
            prevArrow: <SamplePrevArrow transform={64} />,
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: '100px',
            nextArrow: <SampleNextArrow transform={32} />,
            prevArrow: <SamplePrevArrow transform={32} />,
          },
        },
        {
          breakpoint: 640,
          settings: {
            centerPadding: '50px',
            arrows: false,
          },
        },
      ],
      afterChange: (current) => {
        setCurrentSLideNumber(current)
      },
    })

    const sliderElement = sliderRef.current

    if (sliderElement) {
      if (data?.auto_slide) {
        sliderElement.slickPlay()
      } else {
        sliderElement.slickPause()
      }
    }
  }, [data, theme])

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className={` w-full relative  ${isEdit && hoverState && 'component-hover-class'} `}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
      style={{ background: getStyleValue('component_background', 'primary_color', theme.c1) }}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onDelete={() => onDelete(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
          onEdit={() => handleModal()}
          onCopy={() => onCopy(id)}
        />
      )}
      {!isEmpty(setting) && (
        <SliderWrapper className="">
          <Slider ref={sliderRef} {...setting}>
            {data?.slide_data.map((slide, idx) => (
              <div
                key={idx}
                // className={`2xl:h-[80vh] lg:h-[70vh] md:h-[60vh] sm:h-[40vh] h-[175px]`}
                className={` xl:h-[39.9vw] h-[50.76vw] w-full`}
              >
                <div
                  className={`relative flex flex-col w-full h-full ${
                    config[slide.content.position]
                  } `}
                  onClick={() => handleRedirect(slide.link)}
                >
                  {!isEdit && slide?.player?.type && idx === currentSlideNumber ? (
                    <div className="absolute -z-10 w-full h-full">
                      <PassimPlayer
                        data={slide?.player}
                        poster={getTemplateImage(slide?.image_s3key)}
                        controlBar={false}
                        isBanner={true}
                        iconPositionClass={
                          slide.content.position === 'TOP_RIGHT'
                            ? 'decomposed-banner-player-icon-left'
                            : 'decomposed-banner-player-icon'
                        }
                        onClickRedirect={() => handleRedirect(slide.link)}
                      />
                    </div>
                  ) : (
                    <CustomImage
                      imageUrl={getTemplateImage(slide?.image_s3key)}
                      imgClass="absolute -z-10 w-full h-full"
                      loaderClass="w-full h-full absolute -z-10"
                      loaderStyle={{
                        background: '#5A5A5A50',
                      }}
                      imgAlt={'category'}
                    />
                  )}
                  <div className="z-10  lg:w-1/2 flex justify-center items-center px-4 lg:px-0 py-4 lg:py-14">
                    <div
                      className={`w-full lg:w-[78.5%] flex flex-col ${
                        config[slide.content.position]
                      } z-10 `}
                    >
                      {slide.content.heading_type === 'HEADING' ? (
                        <h1
                          style={{
                            color: getStyleValue('heading', 'color', '#fff'),
                            fontFamily: getStyleValue('heading', 'font', ''),
                            textAlign: getStyleValue('heading', 'alignment', ''),
                          }}
                          className={`text-xl md:text-2xl lg:text-4xl xl:text-[40px]   ${getStyleValue(
                            'heading',
                            'font_format',
                            'font-bold',
                          )} line-clamp-3 mb-0`}
                        >
                          {slide.content.heading}
                        </h1>
                      ) : (
                        <div
                          className={`h-[68px] xl:h-[160px] aspect-[${slide?.content?.logo_aspect_ratio}]`}
                        >
                          <CustomImage
                            imageUrl={getTemplateImage(slide.content.logo_s3key)}
                            imgClass="w-full h-full"
                            loaderClass="w-full h-full"
                            loaderStyle={{
                              background: '#5A5A5A50',
                            }}
                            imgAlt={'logo_s3key'}
                          />
                        </div>
                      )}

                      <h3
                        style={{
                          color: getStyleValue('sub_heading', 'color', '#fff'),
                          fontFamily: getStyleValue('sub_heading', 'font', ''),
                          textAlign: getStyleValue('sub_heading', 'alignment', ''),
                        }}
                        className={` font-normal hidden lg:block ${getStyleValue(
                          'sub_heading',
                          'font_format',
                          '',
                        )} text-base mb-0 mt-4`}
                      >
                        {slide.content.sub_heading}
                      </h3>
                      {slide?.cta_button?.enabled && (
                        <button
                          style={{
                            backgroundImage: `linear-gradient(to ${getStyleValue(
                              'button',
                              'gradient',
                              'left',
                            )}, ${getStyleValue(
                              'button',
                              'primary_color',
                              theme.c2,
                            )}, ${getStyleValue('button', 'secondary_color', theme.c2)})`,
                            color: getStyleValue('button', 'text_color', '#fff'),
                            borderRadius: '6px',
                          }}
                          className={`border-0 py-2 px-6  text-xs md:text-lg mt-4 lg:mt-6 `}
                        >
                          {slide.cta_button.text}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </SliderWrapper>
      )}

      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(BohemiaBanner)
