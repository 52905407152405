import React, { useEffect, useState } from 'react'
import '../../components/template/template.scss'
import RowingHeroSection from '../../components/template/rosemary/rowingHeroSection'
import AsymmetricFlow from '../../components/template/rosemary/asymmetricFlow'
import PromotionalLead from '../../components/template/rosemary/promotionalLead'
import PassimCarve from '../../components/template/rosemary/passimCarve'
import InventorySlide from '../../components/template/rosemary/inventorySlide'
import ClassyBanners from '../../components/template/fitness/classyBanners'
import NarrowAdmonition from '../../components/template/fitness/narrowAdmonition'
import DefinityCards from '../../components/template/fitness/definityCards'
import FineTunedGallery from '../../components/template/fitness/fineTunedGallery'
import FlyerIntro from '../../components/template/fitness/flyerIntro'
import MementoSlides from '../../components/template/fitness/mementoSlides'
import WedgedInfo from '../../components/template/fitness/wedgedInfo'
import DecomposedBanners from '../../components/template/gaming/decomposedBanners'
import ExtensiveCarouselRendition from '../../components/template/gaming/extensiveCarouselRendition'
import AdNCarousel from '../../components/template/gaming/adNCarousel'
import ExtensiveCarouselSimple from '../../components/template/gaming/extensiveCarouselSimple'
import TripleCardCarousel from '../../components/template/gaming/tripleCardCarousel'
import BobbingCards from '../../components/template/gaming/bobbingCards'
import DefaultBanner from '../../components/template/default/defaultBanner'
import DefaultSwimlane from '../../components/template/default/defaultSwimlane'
import DefaultArtistSwimlane from '../../components/template/default/defaultArtistSwimlane'
import DefaultVenueSwimlane from '../../components/template/default/defaultVenueSwimlane'
import useAxios from 'axios-hooks'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import PassimCarvePlayer from '../../components/template/rosemary/passimCarvePlayer'
import ClassBannerPlayer from '../../components/template/fitness/classBannerPlayer'
import DefaultBannerPlayer from '../../components/template/default/defaultBannerPlayer'
import DecomposedBannersPlayer from '../../components/template/gaming/decomposedBannersPlayer'
import AdNCarouselPlayer from '../../components/template/gaming/adNCarouselPlayer'
import NewDefaultSwimlane from '../../components/template/default/newDefaultSwimlane'
import AudioSwimlane from '../../components/template/default/audioSwimlane'
import TopTenSwimlane from '../../components/template/default/topTenSwimlane'
import ArticleText from '../../components/template/default/articleText'
import ArticleImage from '../../components/template/default/articleImage'
import CategorySlider from '../../components/template/default/categorySlider'
import useTemplateHook from '../../components/template/templateHook'
import { useTranslation } from 'react-i18next'
import ContentListComponent from '../contentListing/contentListComponent'
import ContentGrid from '../../components/template/default/contentGrid'
import PortraitOwlCarousel from '../../components/template/default/portraitOwlCarousel'
import GloamingBannerSwimlane from '../../components/template/gaming/gloamingBannerSwimlane'
import ProfileMediaPlayer from '../../components/template/default/profileMediaPlayer'
import SectionalComponentWithAd from '../../components/template/default/sectionalComponentWithAd'
import SportsSwimlane from '../../components/template/default/sportsSwimlane'
import SportsPromotionalBlock from '../../components/template/default/sportsPromotionalBlock'
import SportsFragmentedBanner from '../../components/template/default/sportsFragmentedBanner'
import RowingHeroSectionPlayer from '../../components/template/rosemary/rowingHeroSectionPlayer'
import BohemiaBanner from '../../components/template/rosemary/bohemiaBanner'

let totalRecords = 0
let currentPage = 1
let apiHit = false

const ContentListingPage = ({
  pageData,
  componentData,
  data,
  theme,
  setModalState,
  isEdit = false,
}) => {
  const [hoverState, setHoverState] = useState('')
  const { t } = useTranslation()

  const { CONTENT_FILTER_XAVIER, EditIcon, setLoader, PAGE_SIZE } = useTemplateHook()

  const [dataList, setDataList] = useState([])
  const language = localStorage.getItem('language') || 'en'

  const [{ data: contentList, loading: contentListLoading }, contentListCall] = useAxios(
    {
      url: CONTENT_FILTER_XAVIER,
      method: 'post',
    },
    { manual: true },
  )

  useEffect(() => {
    if (contentList && contentList.success) {
      setDataList((dataList) => [...dataList, ...contentList.data])
      totalRecords = contentList.total
      apiHit = false
    }
  }, [contentList])

  useEffect(() => {
    setLoader(contentListLoading)
  }, [contentListLoading])

  useEffect(() => {
    if (!isEmpty(data.content_data) || !isEmpty(data.auto_payload)) {
      hitAPI(1)
    } else {
      setDataList([])
    }
  }, [data])

  const hitAPI = (page) => {
    if (isEdit || isEmpty(data)) return
    let filterPayload =
      data.type === 'STATIC'
        ? { content_ids: data.content_data }
        : {
            system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
            custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
            video_types: ['SPV', 'MPVP', 'MPVC', 'LIVE', 'VOD_LIVE', 'PLAYLIST'],
          }

    let filters = {
      filters: filterPayload,
      page: page,
      order_by:
        data.type === 'STATIC'
          ? ''
          : data?.order_by === 'title'
          ? `title.${language}`
          : data?.order_by,
      reverse: data.type === 'STATIC' ? '' : data?.reverse,
      page_size: PAGE_SIZE,
    }

    apiHit = true
    contentListCall({
      data: filters,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    if (!isEdit) return
    setModalState(data)
  }

  useEffect(() => {
    window.addEventListener('scroll', infiniteScroll)
    return function cleanupListener() {
      window.removeEventListener('scroll', infiniteScroll)
    }
  }, [])

  const infiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >
      0.8 * document.documentElement.offsetHeight
    ) {
      if (!apiHit && totalRecords > currentPage * PAGE_SIZE) {
        apiHit = true
        currentPage += 1

        hitAPI(currentPage)
      }
    }
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const getComponent = (key, data) => {
    if (!key) return
    let componentProps = {
      componentData: data,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
      onSwap: () => {},
      onDelete: () => {},
      onCopy: () => {},
      onAdd: () => {},
    }
    let componentObj = {
      rowingHeroSection: <RowingHeroSection {...componentProps} />,
      asymmetricFlow: <AsymmetricFlow {...componentProps} />,
      promotionalLead: <PromotionalLead {...componentProps} />,
      passimCarve: <PassimCarve {...componentProps} />,
      passimCarvePlayer: <PassimCarvePlayer {...componentProps} />,
      inventorySlide: <InventorySlide {...componentProps} />,
      classyBanners: <ClassyBanners {...componentProps} />,
      classyBannersPlayer: <ClassBannerPlayer {...componentProps} />,
      categoryBanners: <CategorySlider {...componentProps} />,
      narrowAdmonition: <NarrowAdmonition {...componentProps} />,
      definityCards: <DefinityCards {...componentProps} />,
      fineTunedGallery: <FineTunedGallery {...componentProps} />,
      flyerIntro: <FlyerIntro {...componentProps} />,
      mementoSlides: <MementoSlides {...componentProps} />,
      wedgedInfo: <WedgedInfo {...componentProps} />,
      decomposedBanners: <DecomposedBanners {...componentProps} />,
      decomposedBannersPlayer: <DecomposedBannersPlayer {...componentProps} />,
      extensiveCarouselRendition: <ExtensiveCarouselRendition {...componentProps} />,
      adNCarousel: <AdNCarousel {...componentProps} />,
      adNCarouselPlayer: <AdNCarouselPlayer {...componentProps} />,
      extensiveCarousel: <ExtensiveCarouselSimple {...componentProps} />,
      tripleCardCarousel: <TripleCardCarousel {...componentProps} />,
      bobbingCards: <BobbingCards {...componentProps} />,
      defaultBanner: <DefaultBanner {...componentProps} />,
      defaultBannerPlayer: <DefaultBannerPlayer {...componentProps} />,
      defaultSwimlane: <DefaultSwimlane {...componentProps} />,
      newDefaultSwimlane: <NewDefaultSwimlane {...componentProps} />,
      audioSwimlane: <AudioSwimlane {...componentProps} />,
      artistSwimlane: <DefaultArtistSwimlane {...componentProps} />,
      venueSwimlane: <DefaultVenueSwimlane {...componentProps} />,
      topTenSwimlane: <TopTenSwimlane {...componentProps} />,
      articleText: <ArticleText {...componentProps} />,
      articleImage: <ArticleImage {...componentProps} />,
      contentGrid: <ContentGrid {...componentProps} />,
      portraitOwlCarousel: <PortraitOwlCarousel {...componentProps} />,
      gloamingBannerSwimlane: <GloamingBannerSwimlane {...componentProps} />,
      profileMedia: <ProfileMediaPlayer {...componentProps} />,
      sectionalComponentWithAd: <SectionalComponentWithAd {...componentProps} />,
      sportsSwimlane: <SportsSwimlane {...componentProps} />,
      sportsPromotionalBlock: <SportsPromotionalBlock {...componentProps} />,
      sportsFragmentedBanner: <SportsFragmentedBanner {...componentProps} />,
      rowingHeroSectionPlayer: <RowingHeroSectionPlayer {...componentProps} />,
      bohemiaBanner: <BohemiaBanner {...componentProps} />,
    }
    return componentObj[key]
  }

  return (
    <>
      <div className="z-10">
        {!isEmpty(componentData) &&
          componentData.map((info, index) => getComponent(info?.data?.component_key, info?.data))}
      </div>
      <div
        className={`${isEdit && hoverState && 'component-hover-class'} relative`}
        onMouseEnter={() => handleHoverItems(`TOP`)}
        onMouseLeave={() => handleHoverItems('')}
      >
        {isEdit && hoverState.length > 0 && (
          <div
            className="absolute h-[25px] w-[25px] rounded-full bg-[#2172eb] top-4 right-4 z-10 flex justify-center items-center cursor-pointer"
            onClick={() => handleModal()}
          >
            <img src={EditIcon} className="w-[15px] h-[15px]" alt="edit icon" />
          </div>
        )}

        <div className="w-full">
          <div style={{ background: theme.c1 }} className=" w-full flex justify-center">
            <div className="w-full px-10 md:px-16 lg:px-20 mt-[20px] xl:mt-10 flex justify-center relative">
              <div
                style={{
                  borderTopLeftRadius: '6px',
                  borderTopRightRadius: '6px',
                  background: `${getStyleValue('heading', 'background_color', '#0f1721')}95`,
                  color: getStyleValue('heading', 'color', '#00ffff'),
                  fontFamily: getStyleValue('heading', 'font', ''),
                  textAlign: getStyleValue('heading', 'alignment', 'left'),
                }}
                className={`w-full px-6 flex justify-between items-center py-[9px] xl:relative `}
              >
                <p
                  className={`w-full mb-0 line-clamp-1 text-sm xl:text-[22px] ${getStyleValue(
                    'heading',
                    'font_format',
                    'font-bold',
                  )}`}
                >
                  {data?.name || ''}
                </p>
              </div>
            </div>
          </div>
          {isEdit ? (
            <div></div>
          ) : (
            <ContentListComponent
              title={''}
              data={dataList}
              noContentText={contentListLoading ? '' : t('no_content')}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default withTheme(ContentListingPage)
