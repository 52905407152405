import React from 'react'
import CustomImage from '../customImage/customImage'

const MatchesCard = ({ data }) => {
  return (
    <div
      style={{
        aspectRatio: '1102/270',
        borderRadius: '16px',
        border: 'solid 1px #98baff',
        backgroundImage: 'linear-gradient(to right, #d4e4ff 6%, #f9f9f9 50%, #d4e4ff 94%)',
      }}
      className={`mt-4 w-full flex justify-center items-center py-2.5`}
    >
      <div className=" w-full flex flex-col items-center">
        <p className="xl:text-[25px] font-semibold w-full text-center">{data.league}</p>
        <div style={{ width: '74%' }} className="flex justify-between items-center">
          <div className=" flex flex-col items-center justify-center">
            <div
              style={{ width: '130px', height: '130px' }}
              className="border flex justify-center items-center overflow-hidden p-1"
            >
              <CustomImage
                imageUrl={data.team1.logo}
                imgClass="w-full "
                loaderClass={'w-full h-full'}
                imgAlt={data.team1.logo}
              />
            </div>
            <p className="uppercase font-semibold">{data.team1.name}</p>
            <p style={{ color: '#4b4b4b' }} className="text-xs">
              {data.team1.country}
            </p>
          </div>
          <div className=" flex flex-col items-center">
            <div className="flex justify-center items-center">
              <img
                src="https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/90186955-b83d-4f7d-a1b7-ce36c542e298.svg"
                alt="time"
              />
              <p style={{ color: '#cf0202' }} className="xl:text-xl ml-2">
                11h 26m 33s
              </p>
            </div>
            <div
              style={{ width: '323px' }}
              className="mt-4 flex justify-center items-center relative"
            >
              <div style={{ border: 'solid 1px #aecafb' }} className="w-full absolute z-0" />
              <div
                style={{
                  width: '58px',
                  height: '58px',
                  border: 'solid 2px #aecafb',
                  background: '#f3f3f3',
                }}
                className="rounded-full flex items-center justify-center z-10"
              >
                <p style={{ fontSize: '25px', fontWeight: '900' }}>VS</p>
              </div>
            </div>
            <div className="mt-6 text-center" style={{ color: '#4b4b4b' }}>
              <p className="xl:text-xl">
                <span style={{ color: '#0e913e', fontSize: '35px' }} className="font-bold">
                  {data.date}
                </span>{' '}
                {data.monthYear}
              </p>
              <p className="xl:text-lg">{data.venue}</p>
            </div>
          </div>
          <div className=" flex flex-col items-center justify-center">
            <div
              style={{ width: '130px', height: '130px' }}
              className="border flex justify-center items-center overflow-hidden p-1"
            >
              <CustomImage
                imageUrl={data.team2.logo}
                imgClass="w-full"
                loaderClass={'w-full h-full'}
                imgAlt={data.team2.logo}
              />
            </div>
            <p className="uppercase font-semibold">{data.team2.name}</p>
            <p style={{ color: '#4b4b4b' }} className="text-xs">
              {data.team2.country}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MatchesCard
