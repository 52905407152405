import React from 'react'

const ProfileMediaArrow = ({
  color = '#fc0001',
  imgStyle = { width: '283.123px', height: '49.99px' },
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ ...imgStyle }} viewBox="0 0 283.123 49.996">
      <path
        data-name="Subtraction 5"
        d="M2357.042 24882.678h-270.386v-.129l12.653-24.826-.081-.043.081-.037-12.653-24.826v-.135h270.384l12.739 25z"
        transform="translate(-2086.656 -24832.682)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default ProfileMediaArrow
