import React from 'react'

const BannerTrapezium = ({ color = '#011e3e', imgStyle = { width: '949px', height: '700px' } }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={imgStyle} viewBox="0 0 949.979 700.004">
      <path
        data-name="Subtraction 14"
        d="M13084.682 24972H12331v-700h949.979l-196.3 700z"
        transform="translate(-12330.999 -24272)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default BannerTrapezium
