import React from 'react'
import FirstIcon from '../../assets/fantasyLeague/first-prize.svg'
import WinPercentIcon from '../../assets/fantasyLeague/win-precent-icon.svg'
import PeopleIcon from '../../assets/fantasyLeague/people-icon.svg'
import GuranteedIcon from '../../assets/fantasyLeague/guranteed-icon.svg'

const ContestCard = ({ data }) => {
  return (
    <div className="mt-4">
      <div style={{ fontSize: '25px', color: '#4b4b4b' }} className="font-semibold">
        {data.name}
      </div>
      <div
        style={{
          aspectRatio: '1102/198',
          borderRadius: '16px',
          border: 'solid 1px #ff9429',
          backgroundImage: 'linear-gradient(to right, #ffe5cb 6%, #fbf4ee 50%, #ffe5cb 94%)',
          paddingLeft: '3.35%',
          paddingRight: '3.35%',
        }}
        className={` w-full py-2.5`}
      >
        <div className="w-full flex justify-between">
          <div>
            <p style={{ color: '#4b4b4b' }}>Prize Pool</p>
            <p style={{ fontSize: '30px' }} className="font-semibold">
              {data.prize_pool}
            </p>
          </div>
          <div className="flex flex-col items-end">
            <p style={{ color: '#4b4b4b' }}>Entry</p>
            <div
              style={{
                width: '152px',
                height: '40px',
                borderRadius: '6px',
                border: 'solid 1px #0e913e',
              }}
              className="flex justify-center items-center text-lg"
            >
              {data.entry}
            </div>
          </div>
        </div>
        <div
          style={{ background: '#b8b8b8', height: '12px' }}
          className="w-full relative rounded-lg flex items-center mt-4"
        >
          <div
            style={{
              background: '#df0101',
              height: '12px',
              width: `${(parseInt(data.spots_left) / parseInt(data.total_spots)) * 100}%`,
            }}
            className="absolute left-0 rounded-lg"
          />
        </div>
        <div className="flex justify-between mt-1">
          <p style={{ color: '#df0101' }} className="">
            {data.spots_left} Spots Left
          </p>
          <p style={{ color: '#4b4b4b' }} className="">
            {data.total_spots} Spots
          </p>
        </div>
        <div className="flex justify-between mt-5">
          <div className="flex items-center text-xl">
            <img src={FirstIcon} alt="FirstIcon" />
            <span className="ml-3">{data.first_prize}</span>
            <img src={WinPercentIcon} alt="FirstIcon" className="ml-6" />
            <span className="ml-3">{data.win_percent}</span>
            <img src={PeopleIcon} alt="FirstIcon" className="ml-6" />
            <span className="ml-3">{data.how_many_people}</span>
            {data.is_guaranteed ? (
              <>
                <img src={GuranteedIcon} alt="FirstIcon" className="ml-6" />
                <span className="ml-3">Guaranteed</span>
              </>
            ) : null}
          </div>
          <div className="flex items-center">
            <p style={{ color: '#1008ee' }} className="mr-10">
              View Detail
            </p>
            <div
              style={{
                width: '152px',
                height: '40px',
                borderRadius: '6px',
                background: '#0e913e',
              }}
              className="flex justify-center items-center text-white text-lg"
            >
              JOIN
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContestCard
