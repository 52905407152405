import React from 'react'

const HeaderParallelogram = ({
  color = '#4369fb',
  imgStyle = { width: '194px', height: '52px' },
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ ...imgStyle }} viewBox="0 0 193.987 52.002">
      <path
        data-name="Union 7"
        d="m-4016.7 24049-20.3-51.994h72.456v-.006h101.234l20.3 52h-72.456v.006z"
        transform="translate(4036.998 -23996.998)"
        style={{ fill: color }}
      />
    </svg>
  )
}

export default HeaderParallelogram
