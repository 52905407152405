import React, { useEffect, useState } from 'react'
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { withTheme } from 'theming'
import { isEmpty } from 'lodash'
import useTemplateHook from '../templateHook'
import EditMenu from '../editMenu'
import useAxios from 'axios-hooks'
import AddSection from '../addSection'
import './newSwimlane.css'
import LoaderTile from '../../contentCard/loaderTile'
import CustomImage from '../../customImage/customImage'

const SportsSwimlane = ({
  componentData,
  modifyData,
  setModalState,
  isEdit = true,
  onDelete,
  onSwap,
  onCopy,
  theme,
  onAdd,
}) => {
  const { data, id } = componentData
  const [hoverState, setHoverState] = useState('')
  const [hoverIndex, setHoverIndex] = useState('')

  console.log('hover', hoverIndex)

  const {
    customNavigate,
    LG_SIZE,
    SM_SIZE,
    CONTENT_FILTER_XAVIER,
    PortraitDummyImg,
    getTemplateImage,
    playerPageAllowed,
  } = useTemplateHook()
  const [visibleSlides, setVisibleSlides] = useState(2)
  const [contentData, setContentData] = useState([])
  const language = localStorage.getItem('language') || 'en'

  const [{ data: contentList, loading: contentListLoading }, contentListCall] = useAxios(
    {
      url: CONTENT_FILTER_XAVIER,
      method: 'post',
    },
    { manual: true },
  )

  const handleResize = () => {
    if (window.innerWidth > LG_SIZE) {
      setVisibleSlides(6)
    } else if (window.innerWidth < LG_SIZE && window.innerWidth > SM_SIZE) {
      setVisibleSlides(3)
    } else {
      setVisibleSlides(2)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(data.content_data) || !isEmpty(data.auto_payload)) {
      let filterPayload =
        data.type === 'STATIC'
          ? { content_ids: data.content_data }
          : {
              system_defined: data.auto_payload.filter((info) => info.type === 'system_defined'),
              custom_defined: data.auto_payload.filter((info) => info.type === 'custom_defined'),
              video_types: ['SPV', 'MPVP', 'MPVC', 'LIVE', 'VOD_LIVE', 'PLAYLIST'],
            }

      contentListCall({
        data: {
          filters: filterPayload,
          page: 1,
          order_by:
            data.type === 'STATIC'
              ? ''
              : data?.order_by === 'title'
              ? `title.${language}`
              : data?.order_by,
          reverse: data.type === 'STATIC' ? '' : data?.reverse,
          page_size: 15,
        },
      })
    } else {
      setContentData([])
    }
  }, [data])

  useEffect(() => {
    if (contentList && contentList.success) {
      if (data.type === 'STATIC') {
        contentList?.data.sort((a, b) => {
          let sortingArr = data.content_data
          return sortingArr.indexOf(a.content_id) - sortingArr.indexOf(b.content_id)
        })
      }
      setContentData(contentList.data)
    }
  }, [contentList])

  const handleHoverItems = (name) => {
    // if (!isEdit) return
    setHoverState(name)
  }

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }

  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const getLink = (info) => {
    if (info.video_type === 'PLAYLIST' || info.audio_type === 'PLAYLIST') {
      return `/playlist/${info.content_id}`
    }
    if (info.video_type === 'MPVP' || info.audio_type === 'MPAP') {
      return `/parent-content/${info.content_id}`
    } else {
      if ((playerPageAllowed && info.video_type === 'MPVC') || info.video_type === 'MPAC') {
        return `/series-watch/${info?.content_id}`
      }
      return `/content/${info.content_id}`
    }
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <div
      className={`${isEdit && hoverState && 'component-hover-class'}  relative py-[16px] lg:py-8 `}
      style={{
        backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
      }}
      onMouseOver={() => handleHoverItems(`TOP`)}
      onMouseLeave={() => handleHoverItems('')}
    >
      {isEdit && hoverState.length > 0 && (
        <EditMenu
          onEdit={() => handleModal()}
          onDelete={() => onDelete(id)}
          onCopy={() => onCopy(id)}
          onMoveUp={() => onSwap(id, 'up')}
          onMoveDown={() => onSwap(id, 'down')}
        />
      )}
      <CarouselProvider
        naturalSlideWidth={283}
        naturalSlideHeight={382}
        totalSlides={contentData.length}
        visibleSlides={visibleSlides}
        step={6}
      >
        <div
          className="flex  justify-start items-center"
          style={{
            padding: '0 5%',
          }}
        >
          <p
            style={{
              color: getStyleValue('heading', 'color', '#fff'),
              whiteSpace: 'nowrap',
            }}
            className="text-lg xl:text-4xl 2xl:text-[40px] font-semibold mb-0"
          >
            {data?.name}
          </p>
          <div
            style={{
              border: `1.5px solid ${getStyleValue(
                'component_background',
                'line_color',
                '#fc0001',
              )}`,
            }}
            className="w-full ml-4"
          ></div>
        </div>
        <div
          className={`swimlane-container-dock sports-swimlane-slider-class`}
          style={{
            backgroundColor: getStyleValue('component_background', 'primary_color', theme.c1),
            padding: '0 5%',
          }}
        >
          <Slider
            style={{
              //   minHeight: isBrowser ? `23rem` : 'unset',
              maxHeight: '490px',
            }}
            className="mt-2 md:mt-4 lg:mt-8"
          >
            {contentData.map((item, index) => {
              return (
                <Slide key={index} index={index} className="">
                  {contentListLoading ? (
                    <LoaderTile height={'100%'} />
                  ) : (
                    <div
                      className="relative cursor-pointer"
                      onMouseOver={() => setHoverIndex(index)}
                      onMouseLeave={() => setHoverIndex('')}
                      onClick={() => customNavigate('DIRECT', getLink(item), isEdit)}
                    >
                      <div
                        style={{
                          opacity: hoverIndex === index ? '1' : '0',
                          padding: '7%',
                          background: '#00000050',
                          transition: 'opacity 0.25s ease-in',
                          zIndex: '20',
                        }}
                        className="w-full h-full absolute flex justify-center"
                      >
                        <div className="flex flex-col justify-between">
                          <div>
                            <p className="mb-0 text-lg xl:text-xl 2xl:text-[22px] text-white font-semibold line-clamp-2">
                              {item.title}
                            </p>
                            <p
                              style={{ color: '#d5d5d5' }}
                              className="mb-0 mt-2 text-xs xl:text-sm line-clamp-5"
                            >
                              {item.description}
                            </p>
                          </div>
                          <div>
                            {item?.category
                              .filter((e, i) => i < 1)
                              .map((info, idx) => (
                                <p
                                  style={{
                                    background: '#d6ff05',
                                    marginLeft: idx ? '0.625vw' : '',
                                  }}
                                  className={` text-[10px] md:text-base line-clamp-1 w-fit mb-0 px-1 xl:px-3 xl:py-1`}
                                  key={idx}
                                >
                                  {info.name}
                                </p>
                              ))}

                            <div
                              style={{ background: '#fa0001' }}
                              className=" mt-4 xl:mt-4 w-full flex justify-center items-center xl:text-lg 2xl:text-xl py-1 text-white cursor-pointer"
                            >
                              Watch Now
                            </div>
                          </div>
                        </div>
                      </div>

                      <CustomImage
                        imageUrl={getTemplateImage(item?.portrait)}
                        imgClass=" w-full"
                        imgStyle={{ zIndex: '-1' }}
                        loaderClass=" w-full"
                        loaderStyle={{ background: '#5A5A5A50' }}
                        imgAlt={'square Img'}
                        errorPlaceholder={PortraitDummyImg}
                      />
                    </div>
                  )}
                </Slide>
              )
            })}
          </Slider>
        </div>
      </CarouselProvider>
      {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
    </div>
  )
}

export default withTheme(SportsSwimlane)
