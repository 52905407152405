import React, { useEffect, useState } from 'react'
import EditMenu from '../editMenu'
import { withTheme } from 'theming'
import { Link } from 'react-router-dom'
import AddSection from '../addSection'
import useTemplateHook from '../templateHook'
import { useTranslation } from 'react-i18next'
import CustomImage from '../../customImage/customImage'
import { isEmpty } from 'lodash'
import SearchIcon from '../imgComponents/searchIcon'
import styled from '@emotion/styled'
import HeaderTrapezium from '../imgComponents/headerTrapezium'
import HeaderParallelogram from '../imgComponents/headerParallelogram'
import TickerArrow from '../imgComponents/tickerArrow'
import Marquee from 'react-fast-marquee'
// import CartIcon from '../imgComponents/cartIcon'

const StyledListItem = styled.li`
  .dropdown-list {
    background-color: ${(props) => props.background};
  }
  .dropdown-list:hover {
    background-color: ${(props) => props.backgroundHover};
  }
`

const SportsHeader = ({
  componentData,
  modifyData,
  setModalState,
  isEdit,
  onDelete,
  theme,
  onAdd,
  onSwap,
}) => {
  const { t } = useTranslation()
  const { data, id } = componentData
  const [profileToggle, setProfileToggle] = useState(false)
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const {
    customNavigate,
    isLogin,
    userObj,
    logoutCall,
    // setLanguageModal,
    handleSubscription,
    getTemplateImage,
    // ProfileIcon,
    // isMultilingual,
  } = useTemplateHook()
  const [hoverState, setHoverState] = useState('')
  const [searchText, setSearchText] = useState('')
  const [searchBarShow, setSearchBarShow] = useState(false)
  const [tickerArray, setTickerArray] = useState([])

  const [bannerImageHeight, setBannerImageHeight] = useState(0)
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY)
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    setBannerImageHeight(
      parseInt(window.screen.width / 5.13368984) - offset >= 0
        ? parseInt(window.screen.width / 5.13368984) - offset
        : 0,
    )
  }, [offset])

  console.log('offset scroll', offset)

  const [drops, setDrops] = useState(data.site_map.map((dat) => ({ opened: false })))

  useEffect(() => {
    if (!isEmpty(data)) {
      setTickerArray(data?.scrolling_ticker?.ticker_options)
    }
  }, [data])

  const handleDropDown = (selectedDropDownIndex) => {
    let tempDrops = drops
    if (tempDrops[selectedDropDownIndex].opened) tempDrops[selectedDropDownIndex].opened = false
    else tempDrops[selectedDropDownIndex].opened = true
    setDrops([...tempDrops])
  }

  const handleBlurDropDown = (blurDropDownIndex) => {
    let tempDrops = drops
    tempDrops[blurDropDownIndex].opened = false
    setDrops([...tempDrops])
  }

  useEffect(() => {
    setDrops(data.site_map.map((dat) => ({ opened: false })))
  }, [data])

  const handleModal = () => {
    setModalState({
      keyID: id,
      modalData: data,
    })
  }
  const handleAddModal = () => {
    onAdd({
      keyID: id,
      modalData: data,
    })
  }

  const handleHoverItems = (name) => {
    if (!isEdit) return
    setHoverState(name)
  }

  const handleSearchButton = () => {
    if (searchText) {
      customNavigate('DIRECT', `search?search_text=${searchText}`, isEdit)
      // setOpenMobileSearch(false)
      setSearchText('')
      setOpenMobileMenu(false)
      setSearchBarShow(false)
    }
  }

  const handleSearch = (event) => {
    if (event.key === 'Enter' && searchText) {
      customNavigate('DIRECT', `search?search_text=${searchText}`, isEdit)
      setSearchText('')
      setOpenMobileMenu(false)
      setSearchBarShow(false)
    }
  }

  const handleRedirect = (linkObj) => {
    setOpenMobileMenu(false)
    customNavigate('INDIRECT', linkObj, isEdit)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!data?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = data?.style_config[parentKey]?.data.filter((info) => info.key === childKey)

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  return (
    <>
      <header
        className={` ${isEdit ? 'relative' : 'fixed'} z-50 w-full ${
          isEdit && hoverState && 'component-hover-class'
        } flex flex-col`}
        style={{
          background: getStyleValue('component_background', 'primary_color', theme.c1),
        }}
        onMouseEnter={() => handleHoverItems(`TOP`)}
        onMouseLeave={() => handleHoverItems('')}
      >
        {isEdit && hoverState.length > 0 && (
          <EditMenu
            onEdit={handleModal}
            onDelete={() => onDelete(id)}
            onMoveUp={() => onSwap(id, 'up')}
            onMoveDown={() => onSwap(id, 'down')}
          />
        )}
        <CustomImage
          imageUrl={getTemplateImage(data?.banner_s3key)}
          imgClass={` w-full`}
          imgStyle={{ height: bannerImageHeight }}
          loaderClass="w-full"
          loaderStyle={{ aspectRatio: '5.13368984', background: '#5A5A5A50' }}
          imgAlt={'banner_s3key'}
        />
        <div className="w-full">
          <div style={{ marginTop: '-0.677083333vw' }} className="relative w-full xl:flex ">
            <div className="absolute xl:relative flex justify-center items-center z-10 overflow-hidden w-[19%] lg:w-[16%]">
              <HeaderTrapezium
                color={getStyleValue('component_background', 'logo_color', '#fa0001')}
                imgStyle={{ width: '100%', zIndex: '10' }}
              />
              <CustomImage
                imageUrl={getTemplateImage(data?.logo_s3key)}
                imgClass="w-[50%] cursor-pointer absolute left-[19.6%] z-10 xl:as"
                imgStyle={{ aspectRatio: '153/90' }}
                loaderClass=" cursor-pointe"
                loaderStyle={{ background: '#5A5A5A50' }}
                imgAlt={'Logo'}
                onClickRedirect={() => customNavigate('DIRECT', '/home', isEdit)}
              />
              <div
                style={{ background: 'black', marginTop: '0.677083333vw' }}
                className="w-full h-full z-0 absolute left-0 top-0"
              ></div>
            </div>
            <div style={{ marginTop: '0.677083333vw' }} className="w-full">
              <nav
                style={{
                  backgroundColor: 'black',
                }}
                className={` px-[8px] py-[4px] 2xl:py-2.5 w-full`}
              >
                <div
                  className={` ${
                    searchBarShow
                      ? 'w-full'
                      : 'flex items-center flex-wrap justify-between mx-auto max-w-screen-auto'
                  } `}
                >
                  <div className={`${searchBarShow ? 'hidden' : 'flex'} z-0`}>
                    {/* nav bar  */}
                    <div
                      className="hidden justify-between items-center w-full xl:flex lg:w-auto"
                      id="mobile-menu-2"
                    >
                      <ul
                        style={{ listStyle: 'none', margin: 0, padding: 0 }}
                        className="flex items-center"
                      >
                        {data?.site_map.map((siteMapItem, idx) => (
                          <div
                            key={idx}
                            className="group xl:relative static h-full"
                            // style={{ outline: '2px solid red' }}
                            onClick={
                              siteMapItem?.link?.type.length > 0
                                ? () => handleRedirect(siteMapItem?.link)
                                : () => handleDropDown(idx)
                            }
                            onMouseEnter={
                              siteMapItem?.link?.type.length > 0
                                ? () => {}
                                : () => handleDropDown(idx)
                            }
                            onMouseLeave={() => handleBlurDropDown(idx)}
                            onBlur={(e) => {
                              const currentTarget = e.currentTarget
                              // Give browser time to focus the next element
                              requestAnimationFrame(() => {
                                // Check if the new focused element is a child of the original container
                                if (!currentTarget.contains(document.activeElement)) {
                                  // setShowsDropdown(false)
                                  handleBlurDropDown(idx)
                                }
                              })
                            }}
                            tabIndex={100}
                          >
                            <button
                              className={`text-sm ${
                                idx ? 'px-[10px] 2xl:px-[1vw]' : 'pr-[10px] 2xl:pr-[1vw]'
                              }  lg:text-base cursor-pointer  xl:w-max w-full flex xl:justify-start justify-center items-center`}
                              style={{
                                border: 'none',
                                color: getStyleValue('title_primary', 'color', 'white'),
                                background: 'transparent',
                              }}
                            >
                              <span
                                className={`${
                                  drops[idx]?.opened ? 'underline' : ''
                                } ${getStyleValue(
                                  'title_primary',
                                  'font_format',
                                  'font-semibold',
                                )} text-sm lg:text-base xl:text-[min(1vw,16px)] header-menu-underline`}
                                style={{
                                  textDecorationColor: getStyleValue(
                                    'highlighter_color',
                                    'primary_color',
                                    '#fecc00',
                                  ),
                                  textUnderlineOffset: '5px',
                                  fontFamily: getStyleValue('title_primary', 'font', ''),
                                  textAlign: getStyleValue('title_primary', 'alignment', ''),
                                  textDecorationThickness: '2px',
                                }}
                              >
                                {siteMapItem?.title}
                              </span>
                              {siteMapItem?.sub_heading_data.some(
                                (item) => item.text.length > 0,
                              ) && (
                                <svg
                                  className={`${
                                    drops[idx]?.opened
                                      ? 'rotate-profile-arrow'
                                      : 'reset-profile-arrow'
                                  } w-4 h-4 ml-1`}
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                  />
                                </svg>
                              )}
                            </button>
                            <div style={{ height: '22px' }} className="absolute w-full" />
                            <ul
                              className={` absolute w-auto`}
                              style={{
                                transition: 'visibility 0s, opacity 0.3s linear',
                                paddingInlineStart: '0',
                                marginBottom: '0',
                                color: '#fff',
                                whiteSpace: 'nowrap',
                                listStyle: 'none',
                                opacity: drops[idx]?.opened ? 1 : 0,
                                visibility: drops[idx]?.opened ? 'visible' : 'hidden',
                                zIndex: 25,
                              }}
                            >
                              {siteMapItem?.sub_heading_data.map((item, idx) => {
                                return (
                                  item?.text.length > 0 && (
                                    <StyledListItem
                                      key={idx}
                                      // background={`${getStyleValue(
                                      //   'component_background',
                                      //   'primary_color',
                                      //   theme.c1,
                                      // )}`}
                                      background={`#000`}
                                      backgroundHover={`${getStyleValue(
                                        'highlighter_color',
                                        'secondary_color',
                                        '#fecc00',
                                      )}`}
                                    >
                                      <li
                                        className={`dropdown-list cursor-pointer w-full h-max flex xl:justify-start text-sm items-center py-2.5 xl:pl-6 pl-0 xl:pr-6 pr-0  hover:text-white ${getStyleValue(
                                          'title_secondary',
                                          'font_format',
                                          '',
                                        )}`}
                                        style={{
                                          color: getStyleValue('title_secondary', 'color', 'white'),
                                          fontFamily: getStyleValue('title_secondary', 'font', ''),
                                          textAlign: getStyleValue(
                                            'title_secondary',
                                            'alignment',
                                            '',
                                          ),
                                        }}
                                        key={idx}
                                        onClick={() => {
                                          // handleCategoryClick(item._id, item.name)
                                          handleRedirect(item?.link)
                                        }}
                                      >
                                        {item.text}
                                      </li>
                                    </StyledListItem>
                                  )
                                )
                              })}
                            </ul>
                          </div>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {/* search and login button */}
                  <div
                    className={` ${
                      searchBarShow
                        ? 'flex items-center justify-center w-full'
                        : 'flex items-center justify-between'
                    } z-10`}
                    style={{
                      position: 'relative',

                      // outline: '2px solid lime',
                    }}
                  >
                    {!searchBarShow ? (
                      <>
                        {!isLogin ? (
                          <div className="hidden xl:flex justify-between items-center mr-3">
                            <span
                              onClick={() => handleRedirect(data?.add_btn1?.link)}
                              className={` block md:mr-6 cursor-pointer text-sm lg:text-base xl:text-[min(1vw,16px)]`}
                              style={{
                                color: getStyleValue('add_btn1', 'color', 'white'),
                              }}
                            >
                              {data?.add_btn1?.text}
                            </span>

                            <button
                              onClick={() => handleRedirect(data?.add_btn2?.link)}
                              style={{
                                backgroundImage: `linear-gradient(to ${getStyleValue(
                                  'add_btn2',
                                  'gradient',
                                  'left',
                                )}, ${getStyleValue(
                                  'add_btn2',
                                  'primary_color',
                                  'white',
                                )}, ${getStyleValue('add_btn2', 'secondary_color', 'white')})`,
                                color: getStyleValue('add_btn2', 'text_color', theme.c6),
                                borderRadius: '5px',
                              }}
                              className="border-0 shadow-xl font-medium text-sm lg:text-base xl:text-[min(1vw,16px)] md:px-[26px] md:py-1"
                            >
                              {data?.add_btn2?.text}
                            </button>
                          </div>
                        ) : (
                          <div
                            className="flex justify-center items-center"
                            onClick={() => {
                              setProfileToggle(!profileToggle)
                              setOpenMobileMenu(false)
                            }}
                            onBlur={(e) => {
                              const currentTarget = e.currentTarget
                              // Give browser time to focus the next element
                              requestAnimationFrame(() => {
                                // Check if the new focused element is a child of the original container
                                if (!currentTarget.contains(document.activeElement)) {
                                  setProfileToggle(false)
                                  setOpenMobileMenu(false)
                                }
                              })
                            }}
                            tabIndex={3}
                          >
                            <div className="relative">
                              <div className="flex justify-center items-center space-x-3 cursor-pointer">
                                {/* <img
                                  src={ProfileIcon}
                                  alt="ProfileIcon"
                                  className="w-7 h-7 md:w-10 md:h-10"
                                /> */}
                                <div className=" font-semibold text-md mr-3">
                                  <div
                                    style={{ opacity: '0.85' }}
                                    className="flex text-sm lg:text-base xl:text-[min(1vw,16px)] text-white"
                                  >
                                    <span>{userObj?.name ? userObj?.name.split(' ')[0] : ''}</span>
                                    <svg
                                      className={
                                        profileToggle
                                          ? 'rotate-profile-arrow w-4 h-4 ml-2 mt-1'
                                          : 'reset-profile-arrow w-4 h-4 ml-2 mt-1'
                                      }
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M19 9l-7 7-7-7"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              {profileToggle ? (
                                <div
                                  style={{
                                    backgroundColor: `${getStyleValue(
                                      'component_background',
                                      'primary_color',
                                      theme.c1,
                                    )}`,
                                    borderColor: `#ffffff70`,
                                    // opacity: "0.85",
                                    zIndex: 50,
                                  }}
                                  className="hidden xl:block absolute right-0 w-48 px-2 py-3 shadow mt-4"
                                >
                                  <ul
                                    style={{
                                      color: getStyleValue('title_primary', 'color', 'white'),
                                    }}
                                    className="py-1"
                                    aria-labelledby="dropdown"
                                  >
                                    <li>
                                      <Link to="/profile">
                                        <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                          {t('my_profile')}
                                        </p>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/watchlist">
                                        <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                          {t('my_watchlist')}
                                        </p>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/favourites">
                                        <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                          {t('my_favorites')}
                                        </p>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/subscriptions">
                                        <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                          {t('my_subscriptions')}
                                        </p>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/transactions">
                                        <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                          {t('my_transactions')}
                                        </p>
                                      </Link>
                                    </li>
                                    <li onClick={handleSubscription}>
                                      <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                        {t('change_plan')}
                                      </p>
                                    </li>
                                    {/*<li>*/}
                                    {/*  <Link to="/faq">*/}
                                    {/*    <p className="text-lg text-white block px-4 py-2 cursor-pointer user-dropdown-menu">*/}
                                    {/*      Help*/}
                                    {/*    </p>*/}
                                    {/*  </Link>*/}
                                    {/*</li>*/}
                                    <li onClick={logoutCall}>
                                      <p className="text-lg block px-4 py-2 cursor-pointer user-dropdown-menu">
                                        {t('sign_out')}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}

                        <button
                          type="button"
                          className="xl:hidden flex items-center p-2 ml-1 rounded-lg border-0"
                          onClick={() => {
                            setOpenMobileMenu(!openMobileMenu)
                            setProfileToggle(false)
                          }}
                        >
                          <svg
                            className="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ fill: getStyleValue('add_btn1', 'color', theme.c5) }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </>
                    ) : null}

                    <div
                      className={`group flex-shrink-0 ${
                        searchBarShow ? ' w-[88%] ' : 'lg:w-9'
                      }   xl:flex hidden relative`}
                      style={{
                        // position: searchBarShow ? 'relative' : 'relative',
                        // right: searchBarShow ? 'auto' : '100%',

                        transition: searchBarShow ? '500ms' : '0ms',
                        // overflow: 'hidden',
                        marginRight: '0.52vw',
                      }}
                      onClick={searchBarShow ? null : () => setSearchBarShow(true)}
                    >
                      <input
                        type="text"
                        className={`${
                          searchBarShow ? 'block' : 'hidden'
                        }  bg-transparent flex-shrink-0 outline-none px-2 xl:py-2.5 lg:py-1.5 py-[0] w-full lg:text-base text-sm placeholder:italic`}
                        placeholder={t('search_here')}
                        style={{
                          outline: 'none',
                          color: getStyleValue('add_btn1', 'color', '#fff'),
                          fontFamily: 'Arial',
                          fontWeight: 'normal',
                          borderBottom: `1px solid ${getStyleValue('add_btn1', 'color', '#fff')}`,
                        }}
                        disabled={isEdit}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyPress={(e) => handleSearch(e)}
                      />
                      {!searchBarShow ? (
                        <div
                          className=" m-auto flex-shrink-0 cursor-pointer"
                          style={{
                            transition: '500ms',
                            transform: searchBarShow === true ? 'translateX(-20%)' : '',
                          }}
                          onClick={handleSearchButton}
                        >
                          <SearchIcon
                            color={getStyleValue('add_btn1', 'color', '#fff')}
                            imgStyle={{ width: '22.5px', height: '22.1px' }}
                          />
                        </div>
                      ) : null}
                      {searchBarShow ? (
                        <button
                          className="absolute px-1 bottom-0 right-0 font-semibold"
                          onClick={() => setSearchBarShow((prev) => !prev)}
                        >
                          <span
                            style={{ color: getStyleValue('add_btn1', 'color', '#fff') }}
                            className="   text-xl block outline-none focus:outline-none"
                          >
                            ×
                          </span>
                        </button>
                      ) : null}
                    </div>

                    {/* {!searchBarShow ? (
                      <div className="cursor-pointer">
                        <CartIcon
                          color={getStyleValue('add_btn1', 'color', '#fff')}
                          imgStyle={{ width: '22.5px', height: '21px' }}
                        />
                      </div>
                    ) : null} */}
                  </div>

                  <div
                    className={openMobileMenu && !profileToggle ? 'w-full' : 'hidden bg-slate-50'}
                    style={{
                      borderBottom: `1.5px solid ${getStyleValue('add_btn1', 'color', theme.c5)}`,
                    }}
                  >
                    <ul className=" divide-y ">
                      <li
                        style={{ color: getStyleValue('add_btn1', 'color', theme.c5) }}
                        className="flex justify-center items-center pt-3 pb-2 px-6"
                      >
                        <div className="relative w-full">
                          <input
                            style={{
                              border: `1px solid ${getStyleValue('add_btn1', 'color', theme.c5)}`,
                              outline: 'none',
                              color: getStyleValue('add_btn1', 'color', theme.c5),
                            }}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyPress={(e) => handleSearch(e)}
                            className={`w-full bg-transparent px-2 py-1 rounded-md text-sm italic focus:outline-none`}
                            name="search"
                            placeholder={t('search_here')}
                            disabled={isEdit}
                          />
                          <button
                            type="submit"
                            style={{ right: '5px' }}
                            className="absolute top-2 bg-transparent"
                            onClick={handleSearchButton}
                          >
                            <svg
                              className="text-white h-4 w-4 "
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              id="Capa_1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 56.966 56.966"
                              style={{ enableBackground: 'new 0 0 56.966 56.966', fill: 'white' }}
                              xmlSpace="preserve"
                              width="512px"
                              height="512px"
                            >
                              <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                            </svg>
                          </button>
                        </div>
                      </li>
                      {data?.site_map.map((siteMapItem, idx) => (
                        <div
                          key={idx}
                          className="group xl:relative static h-full py-2 "
                          // style={{ outline: '2px solid red' }}
                          onClick={
                            siteMapItem?.link?.type.length > 0
                              ? () => handleRedirect(siteMapItem?.link)
                              : () => handleDropDown(idx)
                          }
                          style={{
                            borderTop: `1.5px solid ${getStyleValue(
                              'add_btn1',
                              'color',
                              theme.c5,
                            )}`,
                          }}
                          onBlur={(e) => {
                            const currentTarget = e.currentTarget
                            // Give browser time to focus the next element
                            requestAnimationFrame(() => {
                              // Check if the new focused element is a child of the original container
                              if (!currentTarget.contains(document.activeElement)) {
                                // setShowsDropdown(false)
                                handleBlurDropDown(idx)
                              }
                            })
                          }}
                          tabIndex={100}
                        >
                          <button
                            className={`xl:w-max w-full flex xl:justify-start justify-center items-center`}
                            style={{
                              border: 'none',
                              color: getStyleValue('title_primary', 'color', 'white'),
                              padding: '0 25px',
                            }}
                          >
                            <span
                              className={`${drops[idx]?.opened ? 'underline' : ''} ${getStyleValue(
                                'title_primary',
                                'font_format',
                                '',
                              )} text-sm lg:text-base header-menu-underline`}
                              style={{
                                textDecorationColor: getStyleValue(
                                  'highlighter_color',
                                  'primary_color',
                                  '#fecc00',
                                ),
                                textUnderlineOffset: '5px',
                                fontFamily: getStyleValue('title_primary', 'font', ''),
                                textAlign: getStyleValue('title_primary', 'alignment', ''),
                                textDecorationThickness: '2px',
                              }}
                            >
                              {siteMapItem?.title}
                            </span>
                            {siteMapItem?.sub_heading_data.some((item) => item.text.length > 0) && (
                              <svg
                                className={`${
                                  drops[idx]?.opened
                                    ? 'rotate-profile-arrow'
                                    : 'reset-profile-arrow'
                                } w-4 h-4 ml-1`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 9l-7 7-7-7"
                                />
                              </svg>
                            )}
                          </button>
                          <ul
                            className={`${drops[idx]?.opened ? 'block' : 'hidden'} 
            xl:absolute static top-[100%] xl:w-auto w-full lg:-left-12 -left-6 xl:left-0`}
                            style={{
                              paddingInlineStart: '0',
                              marginBottom: '0',
                              color: '#fff',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {siteMapItem?.sub_heading_data.map((item, idx) => {
                              return (
                                item?.text.length > 0 && (
                                  <li
                                    className={`cursor-pointer font-bold w-full h-max flex xl:justify-start justify-center text-sm items-center py-2.5 xl:pl-6 pl-0 xl:pr-6 pr-0  hover:text-white ${
                                      getStyleValue('title_secondary', 'font_format')
                                        ? getStyleValue('title_secondary', 'font_format')
                                        : ''
                                    }`}
                                    style={{
                                      color: getStyleValue('title_secondary', 'color', 'white'),
                                      fontFamily: getStyleValue('title_secondary', 'font', ''),
                                      textAlign: getStyleValue('title_secondary', 'alignment', ''),
                                    }}
                                    key={idx}
                                    onClick={() => {
                                      // handleCategoryClick(item._id, item.name)
                                      handleRedirect(item?.link)
                                    }}
                                  >
                                    {item.text}
                                  </li>
                                )
                              )
                            })}
                          </ul>
                        </div>
                      ))}

                      {!isLogin && (
                        <>
                          <li
                            style={{
                              borderTop: `1.5px solid ${getStyleValue(
                                'add_btn1',
                                'color',
                                theme.c5,
                              )}`,
                            }}
                            className="flex justify-center items-center"
                            onClick={() => handleRedirect(data?.add_btn1?.link)}
                          >
                            <p className="text-white font-semibold text-sm block px-4 py-1 cursor-pointer user-dropdown-menu xl:text-[min(1vw,16px)]">
                              {data?.add_btn1?.text}
                            </p>
                          </li>
                          <li
                            style={{
                              color: theme.c5,
                              borderTop: `1.5px solid ${getStyleValue(
                                'add_btn1',
                                'color',
                                theme.c5,
                              )}`,
                            }}
                            className="flex justify-center items-center"
                          >
                            <button
                              onClick={() => handleRedirect(data?.add_btn2?.link)}
                              style={{
                                backgroundImage: `linear-gradient(to ${getStyleValue(
                                  'add_btn2',
                                  'gradient',
                                  'left',
                                )}, ${getStyleValue(
                                  'add_btn2',
                                  'primary_color',
                                  'white',
                                )}, ${getStyleValue('add_btn2', 'secondary_color', 'white')})`,
                                color: getStyleValue('add_btn2', 'text_color', theme.c6),
                                borderRadius: '5px',
                              }}
                              className="border-0 shadow-xl font-medium  text-sm px-8 py-1 my-2"
                            >
                              {data?.add_btn2?.text}
                            </button>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                  <div
                    className={
                      profileToggle && !openMobileMenu
                        ? 'w-full block xl:hidden'
                        : 'hidden bg-slate-50'
                    }
                    style={{
                      borderBottom: `1.5px solid ${getStyleValue('add_btn1', 'color', theme.c5)}`,
                    }}
                  >
                    <ul
                      className=" divide-y "
                      style={{ color: getStyleValue('title_primary', 'color', 'white') }}
                    >
                      <li className="flex justify-center items-center font-semibold">
                        <Link to="/profile">
                          <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                            {t('my_profile')}
                          </p>
                        </Link>
                      </li>
                      <li
                        style={{
                          borderTop: `1.5px solid ${getStyleValue('add_btn1', 'color', theme.c5)}`,
                        }}
                        className=" flex justify-center items-center font-semibold"
                      >
                        <Link to="/watchlist">
                          <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                            {t('my_watchlist')}
                          </p>
                        </Link>
                      </li>
                      <li
                        style={{
                          borderTop: `1.5px solid ${getStyleValue('add_btn1', 'color', theme.c5)}`,
                        }}
                        className="flex justify-center items-center font-semibold"
                      >
                        <Link to="/favourites">
                          <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                            {t('my_favorites')}
                          </p>
                        </Link>
                      </li>
                      <li
                        style={{
                          borderTop: `1.5px solid ${getStyleValue('add_btn1', 'color', theme.c5)}`,
                        }}
                        className=" flex justify-center items-center font-semibold"
                      >
                        <Link to="/subscriptions">
                          <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                            {t('my_subscriptions')}
                          </p>
                        </Link>
                      </li>
                      <li
                        style={{
                          borderTop: `1.5px solid ${getStyleValue('add_btn1', 'color', theme.c5)}`,
                        }}
                        className="flex justify-center items-center font-semibold"
                      >
                        <Link to="/transactions">
                          <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                            {t('my_transactions')}
                          </p>
                        </Link>
                      </li>
                      <li
                        style={{
                          borderTop: `1.5px solid ${getStyleValue('add_btn1', 'color', theme.c5)}`,
                        }}
                        className="flex justify-center items-center font-semibold"
                        onClick={handleSubscription}
                      >
                        <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                          {t('change_plan')}
                        </p>
                      </li>
                      <li
                        style={{
                          borderTop: `1.5px solid ${getStyleValue('add_btn1', 'color', theme.c5)}`,
                        }}
                        className="flex justify-center items-center font-semibold"
                        onClick={logoutCall}
                      >
                        <p className="text-sm block px-4 py-1 cursor-pointer user-dropdown-menu my-2">
                          {t('sign_out')}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
              <div className="w-full justify-between hidden xl:flex xl:text-[min(1vw,16px)]">
                <div
                  style={{
                    width: '10%',
                    marginLeft: '-0.9%',
                  }}
                  className="relative flex justify-center items-center z-10 overflow-hidden"
                >
                  <HeaderParallelogram
                    color={getStyleValue('ticker_style', 'heading_bg_color', '#4369fb')}
                    imgStyle={{ width: '100%', height: '100%', zIndex: '10' }}
                  />
                  <p
                    style={{ color: getStyleValue('ticker_style', 'heading_color', '#fff') }}
                    className="mb-0 absolute z-10"
                  >
                    {data?.scrolling_ticker?.heading}
                  </p>
                  <div
                    style={{
                      background: getStyleValue('ticker_style', 'ticker_bg_color', '#fff'),
                    }}
                    className="w-1/2 h-full absolute right-0"
                  />
                </div>
                <div
                  className="w-full flex items-center "
                  style={{
                    background: getStyleValue('ticker_style', 'ticker_bg_color', '#fff'),
                    color: getStyleValue('ticker_style', 'ticker_color', '#fa0001'),
                    borderBottom: `0.5px solid #707070`,
                    zIndex: -1,
                  }}
                >
                  <div className="w-full">
                    {!isEmpty(tickerArray) ? (
                      <Marquee
                        className=""
                        style={{ zIndex: -1 }}
                        pauseOnHover={true}
                        direction={
                          data.scrolling_ticker.scrolling_direction === 'RIGHTTOLEFT'
                            ? 'right'
                            : 'left'
                        }
                      >
                        {tickerArray.map((info, index) => {
                          return (
                            <div
                              className="flex items-center px-4 cursor-pointer h-full"
                              key={index}
                            >
                              <TickerArrow
                                color={getStyleValue('ticker_style', 'ticker_color', '#fa0001')}
                                imgStyle={{ width: '1.446354167vw' }}
                              />
                              <p className="mb-0" onClick={() => handleRedirect(info?.link)}>
                                {info?.text || ''}
                              </p>
                            </div>
                          )
                        })}
                      </Marquee>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isEdit && hoverState.length > 0 && <AddSection openAdd={handleAddModal} />}
      </header>
      <div style={{ aspectRatio: '4.02' }} className={` w-full ${isEdit && 'hidden'}`}>
        &nbsp;
      </div>
    </>
  )
}

export default withTheme(SportsHeader)
