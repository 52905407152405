import React, { useState } from 'react'
import MatchesCard from '../../components/fantasyLeague/matchesCard'
// import CustomImage from '../../components/customImage/customImage'
import ResultsCard from '../../components/fantasyLeague/resultsCard'

const LiveMatches = () => {
  let [toggle, setToggle] = useState('LIVE')
  let activeStyles = {
    backgroundImage: 'linear-gradient(to right, #000 -1%, #032f7b 98%)',
    color: '#fff',
    fontWeight: '550',
  }
  let inActiveStyles = {
    background: '#e9e9e9',
    color: '#4b4b4b',
  }

  let resultsMatches = [
    {
      league: 'AFRICAN BASKETBALL LEAGUE - Match 13',
      team1: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/a0fc504b-a778-42ef-8f31-61bef0e62b3c.png',
        name: 'ABC Basketball Club',
        country: 'Cape Verde',
      },
      team2: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/7ff79729-8417-4ee7-88ed-c785c28cb7e7.png',
        name: 'BAIRRO Socio',
        country: 'Cape Verde',
      },
      date: 'FRI 20, December 2024',
      venue: 'Dakar Arena, Diamniadio, Senegal - 05:30 PM',
      score: {
        team1: {
          q1: '40',
          q2: '36',
          q3: '30',
          q4: '8',
          f: '114',
        },
        team2: {
          q1: '28',
          q2: '28',
          q3: '28',
          q4: '37',
          f: '127',
        },
      },
      topPerformers: {
        player1: {
          img: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/b0879324-3ff6-4e5b-89cf-f6b9363495e5.png',
          name: 'Patrick Lima',
          data: ['32 PTS', '7 REB', '6 AST'],
        },
        player2: {
          img: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/b916562b-0271-4edc-a91a-382c62756e23.png',
          name: 'Will Tavares',
          data: ['31 PTS', '7 AST'],
        },
      },
    },
    {
      league: 'AFRICAN BASKETBALL LEAGUE - Match 14',
      team1: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/6840b343-36c1-4e94-9e78-b9949750da00.png',
        name: 'ABC Basketball Club',
        country: 'Cape Verde',
      },
      team2: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/a050bf58-93f2-490e-a5f4-526c4952f157.png',
        name: 'BAIRRO Socio',
        country: 'Cape Verde',
      },
      date: 'WED 18, December 2024',
      venue: 'Dakar Arena, Diamniadio, Senegal - 05:30 PM',
      score: {
        team1: {
          q1: '38',
          q2: '36',
          q3: '30',
          q4: '8',
          f: '112',
        },
        team2: {
          q1: '30',
          q2: '28',
          q3: '30',
          q4: '37',
          f: '127',
        },
      },
      topPerformers: {
        player1: {
          img: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/6489fc2a-9c1e-487c-b05c-ff85af8cc82f.png',
          name: 'Jeff Xavier',
          data: ['32 PTS', '7 REB', '6 AST'],
        },
        player2: {
          img: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/c07dea87-3858-431e-a485-f81d52651dd7.png',
          name: 'Ivan Almeida',
          data: ['31 PTS', '7 AST'],
        },
      },
    },
    {
      league: 'NBA Regular Season 2024 - Match 7',
      team1: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/4225bad5-1517-478a-aa7e-346e52a0ef66.png',
        name: 'Golden state warriors',
        country: 'USA',
      },
      team2: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/1c169fe4-6b18-4359-9d89-7d49275c65b8.png',
        name: 'NEW YORK KNICKS',
        country: 'USA',
      },
      date: 'FRI 13, December 2024',
      venue: 'State Farm Arena Atlanta, GA, USA - 05:30 PM',
      score: {
        team1: {
          q1: '38',
          q2: '36',
          q3: '30',
          q4: '8',
          f: '112',
        },
        team2: {
          q1: '30',
          q2: '28',
          q3: '30',
          q4: '37',
          f: '125',
        },
      },
      topPerformers: {
        player1: {
          img: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/b0879324-3ff6-4e5b-89cf-f6b9363495e5.png',
          name: 'Patrick Lima',
          data: ['32 PTS', '7 REB', '6 AST'],
        },
        player2: {
          img: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/b916562b-0271-4edc-a91a-382c62756e23.png',
          name: 'Will Tavares',
          data: ['31 PTS', '7 AST'],
        },
      },
    },
  ]

  let todayMatches = [
    {
      league: 'SUPER LEAGUE BASKETBALL - 2025',
      team1: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/f75dd67e-5591-4a64-91ce-c675765b00bf.png',
        name: 'Charlotte Hornets',
        country: 'CANADA',
      },
      team2: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/6d390b34-0ecc-4327-beb0-17b567a4e173.png',
        name: 'Atlanta Hawks',
        country: 'USA',
      },
      date: 'MON 13',
      monthYear: 'January 2025',
      venue: 'New Yorkers Stadium, USA - 05:30 PM',
    },
    {
      league: 'SUPER LEAGUE BASKETBALL - 2025',
      team1: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/6087ee0c-04f6-4740-b5fe-ffeb7e4b12dd.png',
        name: 'Los angeles lakers',
        country: 'usa',
      },
      team2: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/23923366-9305-4de8-8fc0-a0c75199c668.png',
        name: 'dallas mavericks',
        country: 'USA',
      },
      date: 'MON 13',
      monthYear: 'January 2025',
      venue: 'State Farm Arena Atlanta, GA, USA - 08:30 PM',
    },
    {
      league: 'NBA Regular Season 2025',
      team1: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/f5826db4-c1f8-4cd7-8309-f674069ec9c3.png',
        name: 'Memphis grizzlies',
        country: 'Memphis, usa',
      },
      team2: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/6848d36a-77ef-4859-8c53-f037e01dd0d6.png',
        name: 'New York knicks',
        country: 'ny, USA',
      },
      date: 'MON 13',
      monthYear: 'January 2025',
      venue: 'Chase Center. San Francisco, CA USA - 09:00 PM',
    },
  ]

  let upcomingMatches = [
    {
      league: 'SUPER LEAGUE BASKETBALL - 2025',
      team1: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/23923366-9305-4de8-8fc0-a0c75199c668.png',
        name: 'dallas mavericks',
        country: 'USA',
      },
      team2: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/f5826db4-c1f8-4cd7-8309-f674069ec9c3.png',
        name: 'Memphis grizzlies',
        country: 'Memphis, usa',
      },
      date: 'TUE 14',
      monthYear: 'January 2025',
      venue: 'State Farm Arena Atlanta, GA, USA - 08:30 PM',
    },
    {
      league: 'SUPER LEAGUE BASKETBALL - 2025',
      team1: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/6d390b34-0ecc-4327-beb0-17b567a4e173.png',
        name: 'Atlanta Hawks',
        country: 'USA',
      },
      team2: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/f75dd67e-5591-4a64-91ce-c675765b00bf.png',
        name: 'Charlotte Hornets',
        country: 'CANADA',
      },
      date: 'WED 15',
      monthYear: 'January 2025',
      venue: 'Chase Center. San Francisco, CA USA - 09:00 PM',
    },
    {
      league: 'NBA Regular Season 2025',
      team1: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/6087ee0c-04f6-4740-b5fe-ffeb7e4b12dd.png',
        name: 'Los angeles lakers',
        country: 'usa',
      },
      team2: {
        logo: 'https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/6848d36a-77ef-4859-8c53-f037e01dd0d6.png',
        name: 'New York knicks',
        country: 'ny, USA',
      },
      date: 'THU 16',
      monthYear: 'January 2025',
      venue: 'New Yorkers Stadium, USA - 05:30 PM',
    },
  ]

  return (
    <div style={{ background: '#ffffff' }} className="w-full min-h-screen pb-10">
      <img
        src="https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/7d4327ba-e3be-4d82-81f4-f27d79c6106c-optimized.png"
        alt="banner"
        className="w-full"
      />
      <div className="w-full flex justify-center xl:mt-6">
        <div style={{ width: '1102px' }}>
          <div className="w-full flex items-center justify-between rounded-md overflow-hidden">
            <div
              style={toggle === 'RESULTS' ? activeStyles : inActiveStyles}
              className="w-1/3 cursor-pointer text-center xl:py-[6px]"
              onClick={() => setToggle('RESULTS')}
            >
              RESULTS
            </div>
            <div
              style={toggle === 'LIVE' ? activeStyles : inActiveStyles}
              className="w-1/3 cursor-pointer text-center xl:py-[6px]"
              onClick={() => setToggle('LIVE')}
            >
              LIVE TODAY
            </div>
            <div
              style={toggle === 'UPCOMING' ? activeStyles : inActiveStyles}
              className="w-1/3 cursor-pointer text-center xl:py-[6px]"
              onClick={() => setToggle('UPCOMING')}
            >
              UPCOMING FIXTURES
            </div>
          </div>
          {toggle === 'RESULTS'
            ? [...resultsMatches].map((info, index) => {
                return <ResultsCard data={info} key={index} />
              })
            : null}
          {toggle === 'LIVE'
            ? [...todayMatches].map((info, index) => {
                return <MatchesCard data={info} key={index} />
              })
            : null}
          {toggle === 'UPCOMING'
            ? [...upcomingMatches].map((info, index) => {
                return <MatchesCard data={info} key={index} />
              })
            : null}
        </div>
      </div>
    </div>
  )
}

export default LiveMatches
