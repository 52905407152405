import React from 'react'

const SearchIcon = ({ color = '#fff', imgStyle = { width: '42px', height: '42px' } }) => {
  return (
    <svg
      id="search_black_24dp"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...imgStyle }}
      viewBox="0 0 42 42"
    >
      <path id="Path_58" data-name="Path 58" style={{ fill: 'none' }} d="M0,0H42V42H0Z" />
      <path
        id="Path_59"
        data-name="Path 59"
        style={{ fill: color }}
        d="M24.875,22.25H23.493L23,21.778A11.392,11.392,0,1,0,21.778,23l.472.49v1.382L31,33.607,33.607,31Zm-10.5,0a7.875,7.875,0,1,1,7.875-7.875A7.864,7.864,0,0,1,14.375,22.25Z"
        transform="translate(2.25 2.25)"
      />
    </svg>
  )
}

export default SearchIcon
