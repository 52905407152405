import React, { useContext } from 'react'
import { withTheme } from 'theming'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/app'
import { useNavigate } from 'react-router'

const StillWatchingOverlay = () => {
  const { setOpenAreYouStillWatching } = useContext(AppContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}
      className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className=" w-full flex flex-col items-center justify-center text-white">
        <div style={{ width: '320px' }} className="text-center">
          <p className="mb-0  xl:text-[28px] font-bold">{t('are_you_still_watching')}</p>
          <div
            style={{ border: '4px solid white' }}
            className="mt-10 cursor-pointer"
            onClick={() => {
              setOpenAreYouStillWatching(false)
            }}
          >
            <p className="mb-0  xl:text-[28px] font-bold">{t('continue_watching')}</p>
          </div>
          <div
            style={{ border: '4px solid white' }}
            className="mt-5 cursor-pointer"
            onClick={() => {
              setOpenAreYouStillWatching(false)
              navigate('/')
            }}
          >
            <p className="mb-0  xl:text-[28px] font-bold">{t('exit')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTheme(StillWatchingOverlay)
