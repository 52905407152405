import React, { useState } from 'react'
import ContestCard from '../../components/fantasyLeague/contestCard'

const Contests = () => {
  let [toggle, setToggle] = useState('LIVE')

  let activeStyles = {
    backgroundImage: 'linear-gradient(to right, #000 -1%, #032f7b 98%)',
    color: '#fff',
    fontWeight: '550',
  }
  let inActiveStyles = {
    background: '#e9e9e9',
    color: '#4b4b4b',
  }

  const contests = [
    {
      name: 'CBL MEGA CONTESTS',
      prize_pool: '7,000 USD',
      entry: '49 USD',
      spots_left: '294',
      total_spots: '500',
      first_prize: '2500 USD',
      win_percent: '56%',
      how_many_people: 'Upto 20',
      is_guaranteed: true,
    },
    {
      name: 'CBL VETERAN PLAY CONTEST',
      prize_pool: '3,500 USD',
      entry: '28 USD',
      spots_left: '178',
      total_spots: '750',
      first_prize: '3,500 USD',
      win_percent: '25%',
      how_many_people: 'single',
      is_guaranteed: false,
    },
    {
      name: 'CBL COMEBACK CONTEST',
      prize_pool: '2,100 USD',
      entry: 'Free',
      spots_left: '32',
      total_spots: '50',
      first_prize: '2100 USD',
      win_percent: '33%',
      how_many_people: 'single',
      is_guaranteed: false,
    },
    {
      name: 'CBL END OF SEASON CONTZEST',
      prize_pool: '1,000 USD',
      entry: '50 USD',
      spots_left: '7',
      total_spots: '20',
      first_prize: '1000 USD',
      win_percent: '33%',
      how_many_people: 'single',
      is_guaranteed: false,
    },
  ]

  return (
    <div style={{ background: '#ffffff' }} className="w-full min-h-screen pb-10">
      <div className="w-full relative flex items-center">
        <img
          src="https://cdn.zeplin.io/63d0cc07fec4287a1e418053/assets/36c5a06d-d471-4008-ad2d-2cde3ef6fd59-optimized.png"
          alt="banner"
          className="w-full"
        />
        <div
          style={{ fontSize: '40px', marginLeft: '18.125%' }}
          className="text-white absolute font-bold "
        >
          CBL CONTESTS
        </div>
      </div>
      <div className="w-full flex justify-center xl:mt-6">
        <div style={{ width: '1102px' }}>
          <div className="w-full flex justify-between">
            <div style={{ color: '#4b4b4b' }} className="flex items-center">
              <p style={{}}>Sort Contests by</p>
              <select
                value={'Prize Pool'}
                style={{
                  height: '36px',
                  width: '200px',
                  background: 'white',
                  border: 'solid 0.5px #707070',
                }}
                className="px-2 rounded-lg ml-4 focus:outline-none"
              >
                <option value={'Prize Pool'}> Prize Pool</option>
              </select>
            </div>
            <div
              style={{ width: '464px' }}
              className=" flex items-center justify-between rounded-md overflow-hidden"
            >
              <div
                style={toggle === 'LIVE' ? activeStyles : inActiveStyles}
                className="w-1/2 cursor-pointer text-center xl:py-[6px]"
                onClick={() => setToggle('LIVE')}
              >
                LIVE CONTESTS
              </div>
              <div
                style={toggle === 'PREVIOUS' ? activeStyles : inActiveStyles}
                className="w-1/2 cursor-pointer text-center xl:py-[6px]"
                onClick={() => setToggle('PREVIOUS')}
              >
                PREVIOUS CONTESTS
              </div>
            </div>
          </div>
          <div className="mt-6">
            {[...contests].map((info, index) => {
              return <ContestCard data={info} key={index} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contests
