import React, { useContext, useEffect, useState } from 'react'
import '../../components/template/template.scss'
import RowingHeroSection from '../../components/template/rosemary/rowingHeroSection'
import AsymmetricFlow from '../../components/template/rosemary/asymmetricFlow'
import PromotionalLead from '../../components/template/rosemary/promotionalLead'
import PassimCarve from '../../components/template/rosemary/passimCarve'
import InventorySlide from '../../components/template/rosemary/inventorySlide'
import ClassyBanners from '../../components/template/fitness/classyBanners'
import NarrowAdmonition from '../../components/template/fitness/narrowAdmonition'
import DefinityCards from '../../components/template/fitness/definityCards'
import FineTunedGallery from '../../components/template/fitness/fineTunedGallery'
import FlyerIntro from '../../components/template/fitness/flyerIntro'
import MementoSlides from '../../components/template/fitness/mementoSlides'
import WedgedInfo from '../../components/template/fitness/wedgedInfo'
import DecomposedBanners from '../../components/template/gaming/decomposedBanners'
import ExtensiveCarouselRendition from '../../components/template/gaming/extensiveCarouselRendition'
import AdNCarousel from '../../components/template/gaming/adNCarousel'
import ExtensiveCarouselSimple from '../../components/template/gaming/extensiveCarouselSimple'
import TripleCardCarousel from '../../components/template/gaming/tripleCardCarousel'
import BobbingCards from '../../components/template/gaming/bobbingCards'
import DefaultBanner from '../../components/template/default/defaultBanner'
import DefaultSwimlane from '../../components/template/default/defaultSwimlane'
import DefaultArtistSwimlane from '../../components/template/default/defaultArtistSwimlane'
import DefaultVenueSwimlane from '../../components/template/default/defaultVenueSwimlane'
import { AppContext } from '../../context/app'
import useAxios from 'axios-hooks'
import { CUSTOM_PAGES_API } from '../../utils/apiUrls'
import { useLocation, useNavigate, useParams } from 'react-router'
import { withTheme } from 'theming'
import ReactHtmlParser from 'react-html-parser'
import { isEmpty } from 'lodash'
import 'react-quill/dist/quill.snow.css'
import './customPage.css'
import PassimCarvePlayer from '../../components/template/rosemary/passimCarvePlayer'
import ClassBannerPlayer from '../../components/template/fitness/classBannerPlayer'
import DefaultBannerPlayer from '../../components/template/default/defaultBannerPlayer'
import DecomposedBannersPlayer from '../../components/template/gaming/decomposedBannersPlayer'
import AdNCarouselPlayer from '../../components/template/gaming/adNCarouselPlayer'
import NewDefaultSwimlane from '../../components/template/default/newDefaultSwimlane'
import AudioSwimlane from '../../components/template/default/audioSwimlane'
import TopTenSwimlane from '../../components/template/default/topTenSwimlane'
import ArticleText from '../../components/template/default/articleText'
import ArticleImage from '../../components/template/default/articleImage'
import CategorySlider from '../../components/template/default/categorySlider'
import ContentListingPage from './contentListingPage'
import ContentGrid from '../../components/template/default/contentGrid'
import PortraitOwlCarousel from '../../components/template/default/portraitOwlCarousel'
import GloamingBannerSwimlane from '../../components/template/gaming/gloamingBannerSwimlane'
import ProfileMediaPlayer from '../../components/template/default/profileMediaPlayer'
import SectionalComponentWithAd from '../../components/template/default/sectionalComponentWithAd'
import SportsSwimlane from '../../components/template/default/sportsSwimlane'
import SportsPromotionalBlock from '../../components/template/default/sportsPromotionalBlock'
import TemplateCustomForms from '../../components/template/templateCustomForms/index'
import SportsFragmentedBanner from '../../components/template/default/sportsFragmentedBanner'
import RowingHeroSectionPlayer from '../../components/template/rosemary/rowingHeroSectionPlayer'
import BohemiaBanner from '../../components/template/rosemary/bohemiaBanner'

const CustomPage = ({ theme }) => {
  const pageURL = useParams().pageURL
  const location = useLocation()
  const [componentData, setComponentData] = useState([])
  const [htmlData, setHtmlData] = useState('')
  const { setLoader, componentCustomForms } = useContext(AppContext)
  const [pageData, setPageData] = useState({})
  const navigate = useNavigate()

  const [{ data: pagesList, loading: pagesListLoading }, pagesListCall] = useAxios(
    {
      url: CUSTOM_PAGES_API,
      method: 'get',
    },
    { manual: true },
  )

  useEffect(() => {
    pagesListCall()
  }, [location.pathname])

  useEffect(() => {
    setLoader(pagesListLoading)
  }, [pagesListLoading])

  useEffect(() => {
    if (pagesList && pagesList.success && pageURL) {
      if (location.pathname.split('/')[1] === 'custom') {
        let pageObj = pagesList.data.filter(
          (page) => page?.type === 'CUSTOM' && page?.page_url === `/${pageURL}`,
        )[0]
        if (!pageObj) {
          navigate('/home')
        }
        setPageData(pageObj)
        setComponentData(pageObj?.components)
      }
      if (location.pathname.split('/')[1] === 'static') {
        let pageObj = pagesList.data.filter(
          (page) => page?.type === 'STATIC' && page?.page_url === `/${pageURL}`,
        )[0]
        if (!pageObj) {
          navigate('/home')
        }
        setPageData(pageObj)
        setHtmlData(pageObj?.content)
      }
      if (location.pathname.split('/')[1] === 'content-listing') {
        let pageObj = pagesList.data.filter(
          (page) => page?.type === 'CONTENT_LISTING' && page?.page_url === `/${pageURL}`,
        )[0]
        if (!pageObj) {
          navigate('/home')
        }
        setPageData(pageObj)
        setComponentData(pageObj?.components)
      }
    }
  }, [pagesList])

  const getComponent = (key, data) => {
    if (!key) return
    let componentProps = {
      componentData: data,
      modifyData: () => {},
      setModalState: () => {},
      isEdit: false,
      onSwap: () => {},
      onDelete: () => {},
      onCopy: () => {},
      onAdd: () => {},
    }
    let componentObj = {
      rowingHeroSection: <RowingHeroSection {...componentProps} />,
      asymmetricFlow: <AsymmetricFlow {...componentProps} />,
      promotionalLead: <PromotionalLead {...componentProps} />,
      passimCarve: <PassimCarve {...componentProps} />,
      passimCarvePlayer: <PassimCarvePlayer {...componentProps} />,
      inventorySlide: <InventorySlide {...componentProps} />,
      classyBanners: <ClassyBanners {...componentProps} />,
      classyBannersPlayer: <ClassBannerPlayer {...componentProps} />,
      categoryBanners: <CategorySlider {...componentProps} />,
      narrowAdmonition: <NarrowAdmonition {...componentProps} />,
      definityCards: <DefinityCards {...componentProps} />,
      fineTunedGallery: <FineTunedGallery {...componentProps} />,
      flyerIntro: <FlyerIntro {...componentProps} />,
      mementoSlides: <MementoSlides {...componentProps} />,
      wedgedInfo: <WedgedInfo {...componentProps} />,
      decomposedBanners: <DecomposedBanners {...componentProps} />,
      decomposedBannersPlayer: <DecomposedBannersPlayer {...componentProps} />,
      extensiveCarouselRendition: <ExtensiveCarouselRendition {...componentProps} />,
      adNCarousel: <AdNCarousel {...componentProps} />,
      adNCarouselPlayer: <AdNCarouselPlayer {...componentProps} />,
      extensiveCarousel: <ExtensiveCarouselSimple {...componentProps} />,
      tripleCardCarousel: <TripleCardCarousel {...componentProps} />,
      bobbingCards: <BobbingCards {...componentProps} />,
      defaultBanner: <DefaultBanner {...componentProps} />,
      defaultBannerPlayer: <DefaultBannerPlayer {...componentProps} />,
      defaultSwimlane: <DefaultSwimlane {...componentProps} />,
      newDefaultSwimlane: <NewDefaultSwimlane {...componentProps} />,
      audioSwimlane: <AudioSwimlane {...componentProps} />,
      artistSwimlane: <DefaultArtistSwimlane {...componentProps} />,
      venueSwimlane: <DefaultVenueSwimlane {...componentProps} />,
      topTenSwimlane: <TopTenSwimlane {...componentProps} />,
      articleText: <ArticleText {...componentProps} />,
      articleImage: <ArticleImage {...componentProps} />,
      contentGrid: <ContentGrid {...componentProps} />,
      portraitOwlCarousel: <PortraitOwlCarousel {...componentProps} />,
      customForm: <TemplateCustomForms {...componentProps} formData={componentCustomForms} />,
      gloamingBannerSwimlane: <GloamingBannerSwimlane {...componentProps} />,
      profileMedia: <ProfileMediaPlayer {...componentProps} />,
      sectionalComponentWithAd: <SectionalComponentWithAd {...componentProps} />,
      sportsSwimlane: <SportsSwimlane {...componentProps} />,
      sportsPromotionalBlock: <SportsPromotionalBlock {...componentProps} />,
      sportsFragmentedBanner: <SportsFragmentedBanner {...componentProps} />,
      rowingHeroSectionPlayer: <RowingHeroSectionPlayer {...componentProps} />,
      bohemiaBanner: <BohemiaBanner {...componentProps} />,
    }
    return componentObj[key]
  }
  return (
    <div>
      {pageData?.type === 'CONTENT_LISTING' && (
        <ContentListingPage
          pageData={pageData}
          componentData={pageData?.components}
          data={pageData?.content}
        />
      )}
      {pageData?.type === 'CUSTOM' &&
        componentData.map((info, index) => getComponent(info?.data?.component_key, info?.data))}
      {pageData?.type === 'STATIC' && (
        <div className={`bg-no-repeat bg-right bg-contain mb-15 mt-4 custom-page ql-editor `}>
          <div style={{ color: theme.c4 }} className="md:mt-8 px-8 md:px-20">
            {!isEmpty(pageData) && ReactHtmlParser(htmlData)}
          </div>
        </div>
      )}
    </div>
  )
}

export default withTheme(CustomPage)
